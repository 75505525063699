import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie'

function getToken(){
  return Cookies.get('token');
}
function decodeToken(){
  let token = getToken();
  try {
    return token ? jwt_decode(token) : null;
  } catch (error){
    console.log(error);
    return null;
  }
}

export default new Vuex.Store({
  state: {
    user: decodeToken(),
    loading: 0,
    notificationMessage: null,
    isDarkMode: localStorage.getItem('darkMode') == '1',
  },
  getters: {
    isLogged: state => !!state.user,
    getUser: state => state.user,
    isLoading: state => state.loading > 0,
    getNotificationMessage: state => state.notificationMessage,
    isDarkMode: state => state.isDarkMode,
  },
  mutations: {
    setUser(state, user){
      state.user = user
      state.logged = true
    },
    logout(state){
      state.user = null;
    },
    updateUser(state){
      state.user = decodeToken();
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    showNotificationMessage(state, message) {
      state.notificationMessage = message;
    },
    toggleDarkMode(state){
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('darkMode', state.isDarkMode ? '1':'0');
    }
  },
  actions: {
  },
  modules: {
  }
})
