<template>
  <v-flex xs12>
    <v-card class="d-flex align-center">
      <v-breadcrumbs :items="[['Tarefas', '/'], ...items]">
        <template v-slot:divider>
          <v-icon>keyboard_arrow_right</v-icon>
        </template>
        <template v-slot:item="props">
          <v-breadcrumbs-item :to="props.item[1]" exact :disabled="loading">
            {{props.item[0]}}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer />
      <slot />
    </v-card>
  </v-flex>
</template>
<script>
export default {
  name: "PageBreadcrumbs",
  props: {
    items: { type: Array }
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  }
};
</script>
