<template>
  <div>
    <v-expand-transition>
      <v-progress-linear indeterminate color="primary" v-if="loading" class="mb-1" />
    </v-expand-transition>
    <v-simple-table dense fixed-header>
      <thead>
        <slot name="thead" />
      </thead>
      <tbody>
        <slot name="tbody" />
      </tbody>
    </v-simple-table>
    <v-divider class="grey lighten-1 mt-2" />

    <v-layout align-center justify-space-around wrap>
      <v-flex class="pa-2">
        <v-layout align-center justify-center>
          <span class="caption">
            {{ItemsText}} por página: 
          </span>
          <v-flex shrink mx-2>
            <v-layout align-center>
              <v-select
                outlined hide-details dense class="elevation-0"
                style="width: 100px"
                auto-select-first
                :value="itemsPerPage"
                @input="changePageSize($event)"
                :items="itemsPerPageOptions"
                append-icon="arrow_drop_down"
              />
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex class="text-center pa-2">
        {{`${itemsLength} ${itemsText}`}}
      </v-flex>

      <v-flex class="pa-2">
        <v-layout align-center justify-center>
          <v-btn
            icon small class="elevation-1" :disabled="page == 1"
            @click="changePage(page -1)"
          >
            <v-icon>chevron_left</v-icon>
          </v-btn>
          <v-flex shrink>
            <v-layout class="mx-4" align-center>
              <v-text-field
                solo flat hide-details
                style="width: 50px" class="ma-0 px-0 text-center"
                placeholder="1"
                :value="page"
                @change="changedPage"
                v-mask="'###'"
              />
              /
              <span class="text-center" style="width: 50px">
                {{numPages}}
              </span>
            </v-layout>
          </v-flex>
          <v-btn
            icon small class="elevation-1" :disabled="page == numPages"
            @click="changePage(page +1)"
          >
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>

  </div>
</template>

<script>
// import { mask } from 'vue-the-mask';
export default {
  name: "CustomTable",
  // directives: {
  //   mask
  // },
  props: {
    itemsLength: { type: Number, required: true, },
    itemsText: { type: String, required: true },
    loading: { type: Boolean },
  },
  data(){
    return {
      defaultItemsPerPage: 10,
    };
  },
  computed: {
    ItemsText(){
      let txt = this.itemsText;
      return txt.charAt(0).toUpperCase() + txt.slice(1);
    },
    itemsPerPageOptions(){
      let opts = new Set([10, 25, 50 ]);
      if (this.itemsPerPage > 0){
        opts.add(this.itemsPerPage);
      }
      opts = Array.from(opts);
      opts.sort();
      opts = opts.map((it) => ({
        text: `${it}`,
        value: it,
      }));
      return [...opts, { text: 'Todos', value: -1 }];
    },
    numPages(){
      if (this.itemsPerPage < 0){
        return 1;
      }
      return Math.ceil(this.itemsLength / this.itemsPerPage);
    },
    itemsPerPage(){
      return parseInt(this.$route.params.pageSize) || this.defaultItemsPerPage;
    },
    page(){
      return parseInt(this.$route.params.page) || 1;
    },
    firstItem(){
      return (this.page-1) * this.itemsPerPage;
    }
  },
  watch: {
    page(){ this.changedPageData(); },
    itemsPerPage(){ this.changedPageData(); },
  },
  methods: {
    update(){
      this.$nextTick(() => {
        this.changedPageData();
      });
    },
    changedPageData(){
      let pageSize = this.itemsPerPage;
      if (pageSize < 0) pageSize = this.itemsLength;
      this.$emit('changedPageData', this.firstItem, pageSize);
    },
    changedPage(page){
      let newPage;
      if (!page) newPage = 1;
      else {
        newPage = parseInt(page);
        if (newPage > this.numPages){
          newPage = this.numPages;
        } else if (newPage < 1) {
          newPage = 1
        }
      }
      this.changePage(page);
    },
    changePage(newPage){
      let route = Object.assign(this.$route);
      route.params.pageSize = this.itemsPerPage;
      route.params.page = newPage;
      this.$router.push(route);
    },
    changePageSize(pageSize){
      let route = Object.assign(this.$route);
      route.params.pageSize = pageSize;
      route.params.page = this.page;
      this.$router.push(route);
    }
  },
  created(){
    this.$emit('changedPageData', this.firstItem, this.itemsPerPage);
  }
}
</script>
