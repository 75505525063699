<template>
  <div>
    <PageBreadcrumbs :items="[['Minhas Tarefas','/tasks'],['Nova Tarefa','/tasks/new']]" />

    <TaskForm
      ref="taskForm"
      v-model="task"
      @finalize="createTask"
      :loading="loading"
    />

  </div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import TaskForm from "./TaskForm";

export default {
  name: "NewTask",
  components: {
    PageBreadcrumbs,
    TaskForm
  },
  data(){
    return {
      task: {
        name: "",
        description: "",
        deadline: "",
        highPriority: false,
        sector_id: undefined,
        defineClientTaskResponsible: false,
        filters: [],
        subtasks: [],
        updateOptions: [],
      }
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  },
  methods: {
    createTask() {
      this.reachedStep = 4;
      if (this.$refs.taskForm.validate()) {
        this.$store.commit("setLoading", true);
        this.$axios.post('/tasks/new', this.task)
          .then(response => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Tarefa cadastrada com sucesso",
            );
            this.$router.go(-1);
          })
          .catch(error => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Erro ao cadastrar tarefa",
            );
          });
      }
    }
  }
};
</script>
