<template>
  <v-dialog v-model="showing" max-width="800px" :persistent="loading">
    <v-card>
      <v-card-title>
        Trocando responsável da tarefa
        <v-chip label class="mx-2">
          {{ task.name }}
        </v-chip>
        do cliente
        <v-chip label class="mx-2">
          {{ clientTask.client.name }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <ResponsibleSelect
            v-model="newResponsible"
            :responsibles="responsibles"
            :loading="loadingUsers"
            :disabled="loading"
            :rules="[$rules.required]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="loading" @click="showing = false">
          Cancelar
        </v-btn>
        <v-btn color="success" :loading="loading" @click="changeResponsible()">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
import ResponsibleSelect from '@/components/pages/forms/inputs/ResponsibleSelect'

export default {
  name: "ChangeResponsible",
  props: {
    task: {required: true},
  },
  components: { ResponsibleSelect },
  data: () => ({
    showing: false,
    loadingUsers: false,
    loading: false,
    clientTask: {
      client: {},
    },
    newResponsible: "*",
    responsibles: [],
  }),
  methods: {
    open(clientTask){
      if (this.responsibles.length == 0){
        this.fetchUsersData()
      }
      if (clientTask.responsible.type == "all"){
        this.newResponsible = "*"
      } else {
        this.newResponsible = clientTask.responsible.type + "/" + clientTask.responsible[clientTask.responsible.type].id
      }
      this.clientTask = clientTask
      this.showing = true
    },
    fetchUsersData() {
      this.loadingUsers = true
      this.$axios.get('/tasks/users')
        .then(response => {
          let sectors = response.data;
          let responsibles = [{ name: 'Todos os Usuários', id: '*' }];
          sectors.forEach((sector) => {
            responsibles.push({
              name: sector.name,
              id: `sector/${sector.id}`,
              type: 'sector',
            });
            sector.users.forEach((user) => {
              responsibles.push({
                name: user.name,
                id: `user/${user.id}`,
                type: 'user',
              });
            });
          });
          this.responsibles = responsibles;
          this.loadingUsers = false
        })
    },
    changeResponsible(){
      this.alreadyIncludedError = false
      if (this.$refs.form.validate()){
        this.loading = true
        this.$axios.patch('/task/client-task/responsible', {
          clientTask_id: this.clientTask.id,
          responsible: this.newResponsible,
        })
        .then((response) => {
          this.$refs.form.reset()
          this.$store.commit(
            'showNotificationMessage',
            "Responsável alterado com sucesso"
          );
          this.showing = false
          this.$emit('responsibleChanged')
        })
        .catch((error) => {
          this.$store.commit(
            'showNotificationMessage',
            "Erro inesperado ao alterar responsável"
          );
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>