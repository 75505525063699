<template>
  <div>
    <v-stepper class="mt-2" v-model="currentStep" @change="moveStep($event)">
      <v-stepper-header>
				<v-stepper-step
					:complete="reachedStep > 1"
          :editable="reachedStep >= 1" step="1"
					:rules="[() => reachedStep == 1 || $refs.taskForm1.validate() || 'Dados Incorretos']"
          error-icon="warning" edit-icon="edit" complete-icon="check"
				>
					Dados Gerais
				</v-stepper-step>
        
        <v-divider />
        
        <v-stepper-step
          :complete="reachedStep > 2"
          :editable="reachedStep >= 2" step="2"
          :rules="[() => reachedStep <= 2 || $refs.taskForm2.validate() || 'Dados Incorretos']"
          error-icon="warning" edit-icon="edit" complete-icon="check"
        >
          Seleção de Clientes
        </v-stepper-step>

				<template v-if="task.defineClientTaskResponsible">
          <v-divider />

          <v-stepper-step
            :complete="reachedStep > 3"
            :editable="reachedStep >= 3" step="3"
            :rules="[() => reachedStep <= 3 || $refs.taskForm3.validate() || 'Dados Incorretos']"
            error-icon="warning" edit-icon="edit" complete-icon="check"
          >
            Responsável por Cliente
          </v-stepper-step>
				</template>

				<v-divider />

				<v-stepper-step
					:complete="reachedStep > subtasksStep()"
          :editable="reachedStep >= subtasksStep()" :step="subtasksStep()"
					:rules="[() => reachedStep <= subtasksStep() || $refs[`taskForm${subtasksStep()}`].validate() || 'Dados Incorretos']"
          error-icon="warning" edit-icon="edit" complete-icon="check"
				>
					Criação de Subtarefas
				</v-stepper-step>
      </v-stepper-header>

      <v-stepper-content step="1">
        
        <TaskGeneralData
          ref="taskForm1"
          v-model="task"
          :disabled="loading"
          @error="onError('dados gerais')"
        />

        <v-divider class="my-4" />

        <v-layout class="mb-1 pt-1">
          <v-spacer />
          <v-btn color="success" @click="moveStep(2)" large>
              Continuar
          </v-btn>
        </v-layout>

      </v-stepper-content>

      <v-stepper-content step="2">

        <TaskFilters
          ref="taskForm2"
          v-model="task"
          :disabled="loading"
          @error="onError('filtros')"
          :filteredClients.sync="filteredClients"
          :isCreatingTask="isCreatingTask"
        />

        <v-divider class="my-4" />

        <v-layout class="mb-1 pt-1">
          <v-spacer />
          <v-btn color="success" @click="moveStep(3)" large>
            Continuar
          </v-btn>
        </v-layout>
      </v-stepper-content>

      <v-stepper-content v-if="task.defineClientTaskResponsible" step="3">

        <TaskClientsResponsiblesSelector
          ref="taskForm3"
          v-model="task"
          :filteredClients="filteredClients"
          :disabled="loading"
          @error="onError('filtros')"
          :isCreatingTask="isCreatingTask"
        />

        <v-divider class="my-4" />

        <v-layout class="mb-1 pt-1">
          <v-spacer />
          <v-btn color="success" @click="moveStep(4)" large>
            Continuar
          </v-btn>
        </v-layout>
      </v-stepper-content>

      <v-stepper-content :step="subtasksStep()">

        <TaskSubtasks
          :ref="`taskForm${subtasksStep()}`"
          v-model="task"
          :disabled="loading"
          @error="onError('subtarefas')"
          :isCreatingTask="isCreatingTask"
          :createInitialSubtask="createInitialSubtask"
        />

        <v-divider class="my-4" />

        <v-layout class="mb-1 pt-1">
          <v-spacer />
          <v-btn color="success" @click="finalize()" large>
            Finalizar
          </v-btn>
        </v-layout>
      </v-stepper-content>

    </v-stepper>

  </div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import {
  TaskGeneralData,
  TaskFilters,
  TaskClientsResponsiblesSelector,
  TaskSubtasks
} from "@/components/pages/forms/inputs";
export default {
  name: "TaskForm",
  components: { PageBreadcrumbs, TaskGeneralData, TaskFilters, TaskClientsResponsiblesSelector, TaskSubtasks },
  props: {
    value: { type: Object, required: true },
    loading: { type: Boolean, default: false },
    isCreatingTask: { type: Boolean, default: true },
    createInitialSubtask: { type: Boolean, default: true },
  },
  data(){
    return {
      task: this.value,
      currentStep: 1,
      reachedStep: 1,
      errorHappened: false,
      filteredClients: [],
    };
  },
  watch: {
    currentStep(step) {
      step = parseInt(step);
      if (step > this.reachedStep) {
        this.reachedStep = step;
      }
    },
    value(val) {
      this.task = val;
    },
    task(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    subtasksStep() {
      let steps = 3
      if (this.task.defineClientTaskResponsible)
        ++steps
      return steps
    },
    moveStep(nextStep) {
      let step = this.$refs[`taskForm${this.currentStep}`];
      if (step.validate()){
        this.currentStep = parseInt(nextStep);
        if (step.open) {
          step.open();
        }
      }
    },
    onError(location) {
      if (!this.errorHappened) {
        this.$store.commit("showNotificationMessage", `Erro ao buscar dados de ${location}`);
        this.$router.go(-1);
        this.errorHappened = true;
      }
    },
    validateSteps() {
      for (var i = 1; i <= this.subtasksStep(); ++i) {
        if (!this.$refs[`taskForm${i}`].validate()) {
          return false;
        }
      }
      return true;
    },
    validate() {
      var validation = this.validateSteps();
      if (!validation) {
        this.$store.commit(
          "showNotificationMessage",
          "Preencha todos os dados corretamente"
        );
      }
      return validation;
    },
    finalize() {
      this.reachedStep = this.subtasksStep() + 1;
      this.$emit("finalize", this.task);
    }
  }
};
</script>
