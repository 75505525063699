<template>
  <div>
    <PageBreadcrumbs :items="[['Perfil','/profile']]" />
    <v-card class="mt-2">
      <v-card-title>
        Perfil
      </v-card-title>
      <v-card-text>

        <v-form ref="userDataForm">
          <h3 class="mb-4">Edição de Dados</h3>

          <v-text-field hide-details="auto" filled label="Nome de Usuário *" :rules="[$rules.required,$rules.username]" v-model="user.username" :disabled="loading" />
          <v-text-field hide-details="auto" filled label="Nome Completo *" :rules="[$rules.required]" v-model="user.name" :disabled="loading" />
          <v-text-field hide-details="auto" filled label="E-Mail *" :rules="[$rules.required,$rules.email]" v-model="user.email" :disabled="loading" />
          <v-select
            multiple filled hide-details="auto"
            v-model="user.sectors" :items="sectors"
            :disabled="loading" :rules="[$rules.requiredList]"
            label="Setor *" :loading="loadingSector"
            item-text="name" item-value="id"
          />
          <v-alert v-model="usedUsernameError" type="error" class="mt-2">
            Este nome de usuário já está sendo utilizado por outro usuário
          </v-alert>
          <div class="d-flex justify-end my-1">
            <v-btn color="success" @click="saveUserData()">
              Salvar dados de usuário
            </v-btn>
          </div>
        </v-form>
        <v-divider class="my-4" />

        <v-form ref="passwordForm">
          <h3 class="mb-4">Edição de Senha</h3>
          <v-text-field
            v-model="passwords.current"
            filled label="Senha Atual *"
            :rules="[$rules.required,$rules.passwordMin]"
            :type="showingPassword ? 'text':'password'"
            :disabled="loading" hide-details="auto"
            :append-icon="showingPassword ? 'visibility_off' : 'visibility'"
            @click:append="showingPassword = !showingPassword"
          />
          <v-text-field
            v-model="passwords.new"
            filled label="Nova senha *"
            :rules="[$rules.required,$rules.passwordMin]"
            :type="showingPassword ? 'text':'password'"
            :disabled="loading" hide-details="auto"
            :append-icon="showingPassword ? 'visibility_off' : 'visibility'"
            @click:append="showingPassword = !showingPassword"
          />
          <v-text-field
            filled label="Confirmação da nova senha *"
            :rules="[$rules.required,$rules.equalPassword(() => passwords.new)]"
            :type="showingPassword ? 'text':'password'"
            :append-icon="showingPassword ? 'visibility_off' : 'visibility'"
            @click:append="showingPassword = !showingPassword"
            :disabled="loading" hide-details="auto"
          />
          <v-alert v-model="wrongCurrentPasswordError" type="error" class="mt-2">
            A senha atual digitada não está correta
          </v-alert>
          <div class="d-flex justify-end my-1">
            <v-btn color="success" @click="savePassword()">
              Salvar nova senha
            </v-btn>
          </div>
        </v-form>
        <v-divider class="my-4" />


        <v-btn color="warning" block @click="$logout()">
            sair
        </v-btn>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";

export default {
  name: "Profile",
  components: { PageBreadcrumbs },
  data: () => ({
    user: {},
    passwords: {
      current: "",
      new: "",
    },
    wrongCurrentPasswordError: false,
    usedUsernameError: false,
    showingPassword: false,
    loading: false,
    loadingSector: false,
    sectors: [],
  }),
  methods: {
    fetchUserData(){
      this.loading = true
      this.$axios.get('/user')
        .then((response) => {
          let user = response.data
          user.sectors = user.sectors.map((s) => s.id)
          this.user = user
          this.loading = false
        })
        .catch((error) => {
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao buscar setores"
          )
        });
    },
    fetchSectors(){
      this.loadingSector = true
      this.$axios.get('/user/register/sectors')
        .then((response) => {
          this.sectors = response.data;
          this.loadingSector = false
        })
        .catch((error) => {
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao buscar setores"
          )
        });
    },
    saveUserData(){
      if (this.$refs.userDataForm.validate()){
        this.loading = true
        this.usedUsernameError = false
        this.$axios.put('/user', this.user)
          .then((response) => {
            this.$store.commit(
              "showNotificationMessage",
              "Dados de usuário salvos com sucesso"
            )
          })
          .catch((error) => {
            if (error.response){
              if (error.response.status == 409){
                this.usedUsernameError = true
                return
              }
            }
            this.$store.commit(
              "showNotificationMessage",
              "Erro ao salvar dados de usuário"
            )
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    savePassword(){
      if (this.$refs.passwordForm.validate()){
        this.loading = true
        this.wrongCurrentPasswordError = false
        this.$axios.put('/user/update-password', this.passwords)
          .then((response) => {
            this.$store.commit(
              "showNotificationMessage",
              "Nova senha salva com sucesso"
            )
            this.$refs.passwordForm.reset()
          })
          .catch((error) => {
            if (error.response){
              if (error.response.status == 409){
                this.wrongCurrentPasswordError = true
                return
              }
            }
            this.$store.commit(
              "showNotificationMessage",
              "Erro ao salvar nova senha"
            )
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
  mounted(){
    this.fetchSectors()
    this.fetchUserData()
  },
};
</script>
