<template>
	<v-card hover>
		<v-card-actions primary-title>
			<v-layout fill-height align-center>
				<v-layout wrap>
					<v-flex xs12 class="subheading">
						{{ task.name }}
					</v-flex>
					<v-flex xs12 class="caption">
						{{
							(task.clients.length == 1) ?
							task.clients[0].name
							: `${task.clients.length} clientes`
						}}
					</v-flex>
				</v-layout>
				<v-spacer />
				<v-menu
					:close-on-content-click="false"
					offset-y
					>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" color="accent" class="mx-2" small icon>
							<v-icon>info</v-icon>
						</v-btn>
					</template>
					<v-card>
						<v-card-text>
							<strong>Criada por: </strong> {{ task.createdBy.name }} <br/>
							<strong>Início em:  </strong> {{ utils.timestamp2str(task.creation) }} <br/>
							<strong>Prazo final:  </strong> {{task.deadline || 'Não previsto'}} <br/>
							<strong>Descrição:  </strong> {{ task.description || 'Não informada' }}
						</v-card-text>
					</v-card>
				</v-menu>
				<v-btn
					color="info" small fab
					:to="task.clients.length == 1 ?
						{ name: 'ClientTask', params: { task: task.id, client: task.clients[0].id} }
						: { name: 'Task',     params: { task: task.id } }
					"
				>
					<v-icon>arrow_forward</v-icon>
				</v-btn>
			</v-layout>
		</v-card-actions>
	</v-card>
</template>

<script>
import utils from "@/utils";
export default {
  name: "Task",
  props: {
    task: { type: Object }
  },
  data: function() {
    return { utils };
  }
};
</script>

<style>
v-card__title v-card__title--primary {
  margin-bottom: 0;
}
</style>
