<template>
  <v-navigation-drawer
    permanent fixed stateless touchless
    :mini-variant="mini"
    id="menu"
    class="elevation-2"
  >
    <v-layout column fill-height justify-start>
      <!-- permanent hide-overlay stateless -->

      <v-flex shrink>
        <v-list-item
          class="ma-0"
          to="/profile" :disabled="loading"
          active-class="menu-item-active"
        >
          <v-list-item-icon>
            <!-- <img src="https://randomuser.me/api/portraits/men/85.jpg" /> -->
            <v-icon color="green darken-2">account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{user.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-flex>

      <v-flex>
        <template v-for="item in options">
          <template v-if="item.path != '/users' || user['admin']">
            <v-list-item
              :key="item.title"
              :to="{ name: item.name }" exact
              active-class="menu-item-active"
              :disabled="loading"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>

        <v-list-item>
          <v-list-item-content>
            <v-btn depressed color="success" block @click.stop="mini = !mini" style="margin: 0 auto;">
              <v-icon v-if="mini"> chevron_right </v-icon>
              <v-icon v-else> chevron_left </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-flex>

      <v-spacer />

      <v-flex shrink ma-2>
        <v-btn block depressed class="mb-2"
          :color="isDarkMode ? 'grey lighent-1 black--text':'grey darken-1 white--text'"
          @click="$store.commit('toggleDarkMode')"
        >
          <template v-if="mini">
            <v-icon small>wb_incandescent</v-icon>
          </template>
          <template v-else>
            Modo noturno
          </template>
        </v-btn>
        <v-btn block depressed @click="$logout()">
          Sair
        </v-btn>
      </v-flex>
    </v-layout>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Menu",
  computed: {
    options   (){ return this.$router.options.routes.filter(route => route.title != undefined); },
    user      (){ return this.$store.getters.getUser; },
    loading   (){ return this.$store.getters.isLoading; },
    isDarkMode(){ return this.$store.getters.isDarkMode; },
  },
  data() {
    return {
      mini: this.$vuetify.breakpoint.mdAndDown,
      darkModeSwitch: this.mode,
    };
  },
  watch: {
    mini: function(val) {
      this.$emit("update:mini", val);
    }
  },
  created() {
    this.$emit("update:mini", this.$vuetify.breakpoint.mdAndDown);
  },
};
</script>

<style>
#menu .menu-item-active {
  color: #4caf50;
}
#menu .menu-item-active img {
  box-shadow: 0 0 0 2px #4caf50;
}
</style>
