<template>
  <div>
    
    <!-- TOOLBAR -->
    <div class="d-flex my-2">
      <v-text-field
        color="info" outlined
        hide-details clearable small block
        v-model.lazy="searchQueryLocal"
        @keyup.enter="searchQuery = searchQueryLocal"
        :readonly="loadingSearch"
        :loading="loadingSearch"
        label="Digite para buscar filtrar as tarefas"
        dense
        class="elevation-2"
      />

      <v-tooltip left :open-delay="250">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="table = !table" color="primary" fab class="mt-1 ml-2 mr-1" x-small elevation="2">
            <v-icon> {{table ? "view_agenda":"table_chart"}} </v-icon>
          </v-btn>
        </template>
        <span>
          Trocar para modo de {{table ? "blocos":"tabela"}}
        </span>
      </v-tooltip>
    </div>

    <!-- DATA TABLE -->
    <div class="mt-1" v-if="table">
      <v-card class="elevation-4">
        <CustomTable
          ref="dataTable"
          :itemsLength="numTasks" itemsText="tarefas"
          :loading="loading"
        >
          <template v-slot:thead>
            <th v-if="$vuetify.breakpoint.smAndUp" class="text-left pt-3 pb-2 pl-4">
              Tarefa
            </th>
            <th class="text-left py-1 pl-4"> Cliente </th>
            <th class="text-left py-1 pl-4"> Prazo final </th>
            <th class="text-left py-1 pl-4"></th>
          </template>

          <template v-slot:tbody>
            <tr v-if="fetchError">
              <td colspan="4" class="text-center py-2">
                Erro inesperado ao buscar tarefas<br/>
                <v-btn color="secondary" small depressed class="mt-2" @click="loadTasks()">
                  tentar novamente
                </v-btn>
              </td>
            </tr>
            <tr v-else v-for="task in pageTasks" :key="task.id">
              <td v-if="$vuetify.breakpoint.smAndUp">
                {{task.name}}
              </td>
              <td>
                {{
                  (task.num_clients == 1) ?
                  task.client.name :
                  `${task.num_clients} clientes`
                }}
              </td>
              <td>
                {{task.deadline || 'Não previsto'}}
              </td>
              <td>
                <v-layout align-center>
                  <v-flex shrink>
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="accent" small icon>
                          <v-icon>info</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-text>
                          <strong>Criada por: </strong> {{task.createdBy.name}} <br/>
                          <strong>Início em:  </strong> {{$utils.timestamp2str(task.creation) }} <br/>
                          <strong>Descrição:  </strong> {{task.description || 'Não informada'}}
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-flex>
                  <v-flex shrink class="ml-1 my-1">
                    <v-tooltip top :close-delay="0" :open-delay="500">
                      <span> acessar tarefa </span>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :disabled="loading" v-on="on"
                          color="primary" small fab class="elevation-1"
                          :to="task.num_clients == 1 ?
                              { name: 'ClientTask', params: { task: task.id, client: task.client.id} }
                            : { name: 'Task',       params: { task: task.id } }
                          "
                        >
                            <v-icon>arrow_forward</v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
          </template>
        </CustomTable>

      </v-card>
    </div>

    <!-- DATA GRID -->
    <v-layout v-else wrap>
      <template v-for="task in filteredTasks">
        <v-flex :key="task.id" xs12 sm6 lg4 xl3>
          <Task :task="task" class="ma-1" />
        </v-flex>
      </template>
    </v-layout>

  </div>
</template>

<script>
import Task from "./Task.vue";
import CustomTable from "./CustomTable.vue";

export default {
  name: "TasksList",
  components: { Task, CustomTable },
  data() {
    return {
      searchQueryLocal: this.$route.params.searchQuery,
      searchQuery: this.$route.params.searchQuery,
      table: true,
      loadingSearch: false,
      fetchError: false,
      pageTasks: [],
      numTasks: 0,
      timeout: undefined,
      changing: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    },
  },
  watch: {
    searchQueryLocal(txt){
      if (this.timeout){
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.searchQuery = txt;
      }, 600);
    },
    searchQuery(txt){
      let params = {
        pageSize: this.$route.params.pageSize || 10,
        page: 1,
      };
      if (txt) params.searchQuery = txt;
      this.$router.replace({
        name: this.$route.name,
        params,
      });
    },
    '$route.params'(){
      this.loadTasks();
    },
  },
  methods: {
    loadTasks() {
      clearTimeout(this.timeout);
      this.fetchError = false;
      let endpoint
      if (this.$route.name == 'tasks-completed'){
        endpoint = '/tasks/completed';
      } else {
        endpoint = '/tasks';
      }
      const pageSize = parseInt(this.$route.params.pageSize) || 10;
      const page = parseInt(this.$route.params.page) || 1;
      if (pageSize == -1 && page > 1){
        let params = this.$route.params;
        params.page = 1;
        this.$router.replace({
          name: this.$route.name,
          params,
        });
        return;
      }
      this.$store.commit("setLoading", true);
      this.$axios.get(endpoint, {
        params: {
          search_query: this.searchQuery,
          limit: pageSize,
          offset: (page-1) * pageSize,
        }
      })
        .then(response => {
          this.numTasks = response.data.length;
          this.pageTasks = response.data.tasks;
          this.$refs.dataTable.update();
          let params = this.$route.params;
          if (params.page > 1 && params.page > Math.ceil(this.numTasks/params.pageSize)){
            params.page = 1;
            this.$router.replace({
              name: this.$route.name,
              params,
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao buscar tarefas do usuário"
          );
          this.fetchError = true;
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loadTasks();
    });
  }
};
</script>
