<template>
  <div>
    <PageBreadcrumbs :items="[
      ['Minhas Tarefas','/tasks'],
      ['Padrões','/tasks/patterns'],
      ['Editar Tarefa Padrão',`/tasks/pattern/edit/${patternID}`]
    ]" />

    <TaskForm
      ref="taskForm"
      v-model="task"
      @finalize="editTask"
      :loading="loading"
      :isCreatingTask="false"
    />

  </div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import TaskForm from "./TaskForm";

export default {
  name: "EditPatternTask",
  components: {
    PageBreadcrumbs,
    TaskForm
  },
  data() {
    return {
      patternID: this.$route.params.id,
      task: {
        pattern: true,
        name: "",
        description: "",
        subtasks: [],
        filters: [],
        updateOptions: [],
      }
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  },
  methods: {
    editTask() {
      if (this.$refs.taskForm.validate()) {
        this.$store.commit("setLoading", true);
        let task = Object.assign({}, this.task);
        delete task.pattern;
        this.$axios.put('/tasks/patterns', task)
          .then(response => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Tarefa padrão salva com sucesso"
            );
            this.$router.go(-1);
          })
          .catch(error => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Erro ao alterar tarefa padrão"
            );
          });
      }
    }
  },
  created() {
    this.$store.commit("setLoading", true);
    this.$axios.get(`/tasks/patterns/${this.patternID}`)
      .then(response => {
        let task = response.data;
        this.$store.commit("setLoading", false);
        task.pattern = true;
        this.task = task;
      })
      .catch(error => {
        this.$store.commit("setLoading", false);
        this.$store.commit(
          "showNotificationMessage",
          "Erro ao buscar dados da tarefa padrão"
        );
        this.$router.go(-1);
      });
  }
};
</script>
