<template>
  <div>
    <v-autocomplete
        v-model="input"
        filled :label="label"
        :items="items" :disabled="disabled" :rules="rules"
        :multiple="multiple"
        :item-value="itemValue || 'value'"
        :item-text ="itemText || 'text'"
        append-icon="arrow_drop_down"
    >
      <template v-slot:append-outer>
        <v-dialog v-model="dialog" max-width="400px">
          <template v-slot:activator="{ on }">
            <v-layout align-center>
              <v-btn color="secundary" :disabled="disabled" text icon small v-on="on" style="margin-top: -6px">
                <v-icon>add</v-icon>
              </v-btn>
            </v-layout>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"> {{labelNew}} </span>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="name" :label="labelName" />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="normal" @click="dialog = false; name = ''"> Cancelar </v-btn>
              <v-btn text color="success" @click="newItem()"> Cadastrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "DynamicSelect",
  props: [
    "value",
    "label", "labelNew", "labelName",
    "disabled", "rules", "multiple",
    "items", "item-value", "item-text"
  ],
  data() {
    return {
      input: this.value,
      dialog: false,
      name: ""
    };
  },
  watch: {
    value(val) {
      this.input = val;
    },
    input(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    newItem() {
      this.dialog = false;
      this.items.push(this.name);
      if (this.multiple !== undefined) {
        this.input.push(this.name);
      } else {
        this.input = this.name;
      }
      this.name = "";
    }
  }
};
</script>
