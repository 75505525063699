<template>
  <div>
    <PageBreadcrumbs :items="[
      ['Minhas Tarefas','/tasks'],
      ['Concluídas','/tasks/completed'],      
    ]">
      <v-btn
        to="/tasks"
        depressed
        color="success"
        class="mr-3 font-weight-medium"
        style="font-size: 11px"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Voltar para Tarefas ativas
      </v-btn>
    </PageBreadcrumbs>

    <TasksList />

  </div>
</template>

<script>
import { PageBreadcrumbs, TasksList } from "@/components/core";

export default {
  name: "TasksCompleted",
  components: { PageBreadcrumbs, TasksList },
};
</script>
