import Vue from "vue";
import Router from "vue-router";
import {
  Home,
  Tasks,
  TasksCompleted,
  Task,
  ClientTask,
  Patterns,
  Clients,
  Users,
  Profile
} from "@/components/pages";

import {
  NewClient,
  EditClient,
  NewTask,
  NewPatternTask,
  CreateTaskFromPattern,
  EditPatternTask
} from "@/components/pages/forms";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    { path: "/profile", name: "Profile", component: Profile },
    {
      path: "/",
      redirect: '/tasks/list/' 
      // name: "Home",
      // component: Home,
      // title: "Página Inicial",
      // icon: "home"
    },
    { path: '/tasks', redirect: '/tasks/list/' },
    {
      name: "tasks-active",
      path: "/tasks/list/:pageSize?/:page?/:searchQuery?",
      name: "Tasks",
      component: Tasks,
      title: "Minhas Tarefas",
      icon: "view_list",
    },
    {
      name: "tasks-completed",
      path: "/tasks/completed/:pageSize?/:page?/:searchQuery?",
      component: TasksCompleted,
    },
    { path: "/tasks/new", name: "NewTask", component: NewTask },
    { path: "/tasks/patterns/:pageSize?/:page?/:searchQuery?", name: "Patterns", component: Patterns },
    { path: "/tasks/task/:task/:pageSize?/:page?/:searchQuery?", name: "Task", component: Task },
    { path: "/tasks/client-task/:task/:client", name: "ClientTask", component: ClientTask },
    {
      path: "/tasks/pattern/new",
      name: "NewPatternTask",
      component: NewPatternTask
    },
    {
      path: "/tasks/pattern/use/:id",
      name: "CreateTaskFromPattern",
      component: CreateTaskFromPattern
    },
    {
      path: "/tasks/pattern/edit/:id",
      name: "EditPatternTask",
      component: EditPatternTask
    },
    // {
    //   path: "/historic",
    //   name: "Historic",
    //   component: Historic,
    //   title: "Histórico",
    //   icon: "explore"
    // },
    { path: '/clients', redirect: '/clients/list/' },
    {
      path: "/clients/list/:pageSize?/:page?/:searchQuery?",
      name: "Clients",
      component: Clients,
      title: "Clientes",
      icon: "import_contacts"
    },
    { path: "/clients/new", name: "NewClient", component: NewClient },
    { path: "/clients/edit/:id", name: "EditClient", component: EditClient },
    {
      path: "/users",
      name: "Users",
      component: Users,
      title: "Usuários",
      icon: "lock_outline"
    }
  ]
});
