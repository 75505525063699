var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex my-2"},[_c('v-text-field',{staticClass:"elevation-2",attrs:{"color":"info","outlined":"","hide-details":"","clearable":"","small":"","block":"","readonly":_vm.loadingSearch,"loading":_vm.loadingSearch,"label":"Digite para buscar filtrar as tarefas","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchQuery = _vm.searchQueryLocal}},model:{value:(_vm.searchQueryLocal),callback:function ($$v) {_vm.searchQueryLocal=$$v},expression:"searchQueryLocal"}}),_c('v-tooltip',{attrs:{"left":"","open-delay":250},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-1 ml-2 mr-1",attrs:{"color":"primary","fab":"","x-small":"","elevation":"2"},on:{"click":function($event){_vm.table = !_vm.table}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.table ? "view_agenda":"table_chart")+" ")])],1)]}}])},[_c('span',[_vm._v(" Trocar para modo de "+_vm._s(_vm.table ? "blocos":"tabela")+" ")])])],1),(_vm.table)?_c('div',{staticClass:"mt-1"},[_c('v-card',{staticClass:"elevation-4"},[_c('CustomTable',{ref:"dataTable",attrs:{"itemsLength":_vm.numTasks,"itemsText":"tarefas","loading":_vm.loading},scopedSlots:_vm._u([{key:"thead",fn:function(){return [(_vm.$vuetify.breakpoint.smAndUp)?_c('th',{staticClass:"text-left pt-3 pb-2 pl-4"},[_vm._v(" Tarefa ")]):_vm._e(),_c('th',{staticClass:"text-left py-1 pl-4"},[_vm._v(" Cliente ")]),_c('th',{staticClass:"text-left py-1 pl-4"},[_vm._v(" Prazo final ")]),_c('th',{staticClass:"text-left py-1 pl-4"})]},proxy:true},{key:"tbody",fn:function(){return [(_vm.fetchError)?_c('tr',[_c('td',{staticClass:"text-center py-2",attrs:{"colspan":"4"}},[_vm._v(" Erro inesperado ao buscar tarefas"),_c('br'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"secondary","small":"","depressed":""},on:{"click":function($event){return _vm.loadTasks()}}},[_vm._v(" tentar novamente ")])],1)]):_vm._l((_vm.pageTasks),function(task){return _c('tr',{key:task.id},[(_vm.$vuetify.breakpoint.smAndUp)?_c('td',[_vm._v(" "+_vm._s(task.name)+" ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s((task.num_clients == 1) ? task.client.name : ((task.num_clients) + " clientes"))+" ")]),_c('td',[_vm._v(" "+_vm._s(task.deadline || 'Não previsto')+" ")]),_c('td',[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"accent","small":"","icon":""}},on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('strong',[_vm._v("Criada por: ")]),_vm._v(" "+_vm._s(task.createdBy.name)+" "),_c('br'),_c('strong',[_vm._v("Início em: ")]),_vm._v(" "+_vm._s(_vm.$utils.timestamp2str(task.creation))+" "),_c('br'),_c('strong',[_vm._v("Descrição: ")]),_vm._v(" "+_vm._s(task.description || 'Não informada')+" ")])],1)],1)],1),_c('v-flex',{staticClass:"ml-1 my-1",attrs:{"shrink":""}},[_c('v-tooltip',{attrs:{"top":"","close-delay":0,"open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-1",attrs:{"disabled":_vm.loading,"color":"primary","small":"","fab":"","to":task.num_clients == 1 ?
                            { name: 'ClientTask', params: { task: task.id, client: task.client.id} }
                          : { name: 'Task',       params: { task: task.id } }}},on),[_c('v-icon',[_vm._v("arrow_forward")])],1)]}}],null,true)},[_c('span',[_vm._v(" acessar tarefa ")])])],1)],1)],1)])})]},proxy:true}],null,false,871497860)})],1)],1):_c('v-layout',{attrs:{"wrap":""}},[_vm._l((_vm.filteredTasks),function(task){return [_c('v-flex',{key:task.id,attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c('Task',{staticClass:"ma-1",attrs:{"task":task}})],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }