<template>
	<div v-if="!!task">
		<PageBreadcrumbs :items="[
			['Minhas Tarefas','/tasks'],
			[`${task.name}`,`/tasks/task/${taskID}`],
			[`${task.clientTask.client.name}`,`/tasks/task-client/${taskID}/${clientID}`]
		]" />

		<v-card class="mt-2">
			<v-card-title class="pb-0">
        <div class="d-flex align-center">
          <v-btn icon small @click="$router.go(-1)" :disabled="loading">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <h4 class="ml-2">
            {{task.name}}
          </h4>
          <v-icon>chevron_right</v-icon>
          <span>
            {{task.clientTask.client.name}}
          </span>
        </div>
				<template v-if="task.highPriority">
					<v-spacer/>
					<v-tooltip left>
						<span>prioridade alta</span>
						<v-btn
							fab small color="yellow darken-3"
							class="elevation-2"
						>
							<v-icon color="white">
								priority_high
							</v-icon>
						</v-btn>
					</v-tooltip>
				</template>
			</v-card-title>
			<v-card-text>
				<p
          class="mt-3"
          v-html="$utils.parseText( task.description || 'Sem descrição')"
        />
        <strong>Criada por: </strong> {{task.createdBy.name}} <br/>
				<strong>Iniciada em:</strong> {{utils.timestamp2str(task.creation)}} <br />
				<strong>Prazo final:</strong> {{task.deadline || 'Não previsto'}}
				<v-divider class="my-3" />
				<h2 class="mb-3 gray--text darken-4--text">Subtarefas</h2>
				<v-layout wrap>
					<template v-for="(subtask, index) in task.clientTask.subtasks">
						<v-flex :key="subtask.id" xs12 md6 lg6 xl4 class="pa-1">
							<v-card class="elevation-4" style="height: 100%">
								<v-card-text class="black--text">
                  <div class="d-flex">

                    <SubtaskCheckbox
                      :updateOptions="task.updateOptions" 
                      :subtask.sync="task.clientTask.subtasks[index]"
                      :loading="loading"
                    />

                    <div class="ml-3">
                      <strong> {{subtask.name}} </strong>
                      <p class="mb-0 text-justify"
                        v-html="$utils.parseText( subtask.description || '' )"
                      />
                      <strong>Responsável:</strong> 
                      &nbsp;
                      <v-tooltip top v-if="subtask.responsible.type == 'user'">
                        <strong>Setor:</strong> {{subtask.responsible.user.sector}}
                        <template #activator="{on}">
                          <span v-on="on">
                            {{subtask.responsible.user.name}}
                          </span>
                        </template>
                      </v-tooltip>
                      <template v-else>
                        {{
                          subtask.responsible.type == 'sector' ?
                          'Setor ' + subtask.responsible.sector.name
                          :'Todos'
                        }}
                      </template>
                        <v-layout column justify-start>
                          <template v-for="(link, iLink) in task.subtasks[index].links">
                            <v-flex :key="iLink">
                              <v-btn small text color="info" :href="$utils.parseLink(link.link)" target="_blank">
                                {{link.identification}}
                                <v-icon class="ml-2" small>open_in_new</v-icon>
                              </v-btn>
                            </v-flex>
                          </template>
                        </v-layout>
                    </div>

                    <v-spacer />

                    <div class="caption text-left flex-shrink-1" v-if="task.subtasks[index].deadline">
                      <strong>Prazo final: </strong><br />
                      {{task.subtasks[index].deadline}}
                    </div>

                    <div class="flex-shrink-1">
                      <v-menu max-width="600" left :close-on-content-click="false">
                        <template v-slot:activator="{ on }">

                          <v-btn
                            v-on="on"
                            color="primary" class="ma-0 ml-2"
                            small icon outlined depressed
                          >
                            <v-icon small>comment</v-icon>
                          </v-btn>

                        </template>
                        <v-card>

                          <Updates :subtask.sync="task.clientTask.subtasks[index]" />

                        </v-card>
                      </v-menu>
                    </div>

                  </div>
								</v-card-text>
							</v-card>
						</v-flex>
					</template>
				</v-layout>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import SubtaskCheckbox from "@/components/pages/task_items/SubtaskCheckbox";
import Updates from "@/components/pages/task_items/Updates";
import utils from "@/utils";

export default {
  name: "ClientTask",
  components: { PageBreadcrumbs, SubtaskCheckbox, Updates },
  data() {
    return {
      utils,
      task: {
        clientTask: {
          client: {},
          subtasks: [],
        },
        createdBy: {},
        subtasks: [],
      }
    };
  },
  computed: {
    taskID() {
      return this.$route.params.task;
    },
    clientID() {
      return this.$route.params.client;
    },
    loading() {
      return this.$store.getters.isLoading;
    }
  },
  watch: {},
  methods: {
    fetchClientTask() {
      this.$store.commit("setLoading", true);
      this.$axios.get(`/tasks/client/${this.taskID}/${this.clientID}`)
        .then(response => {
          this.task = response.data;
        })
        .catch(error => {
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao buscar dados da tarefa do cliente"
          );
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        })
    }
  },
  created() {
    this.fetchClientTask();
  }
};
</script>