<template>
  <v-autocomplete
    :value="this.value" @input="$emit('input', $event)"
    :items="responsibles"
    item-text="name" item-value="id"
    :filled="filled" :dense="dense" :solo="solo"
    label="Responsável *"
    :disabled="disabled"
    :rules="rules"
    @change="$emit('change', $event)" hide-details="auto"
    append-icon="arrow_drop_down"
  >
    <template slot="item" slot-scope="data">
      <v-layout column>
        <v-divider v-if="data.item.type == 'sector'" class="mb-2" />
        <span :class="{'font-weight-medium': data.item.type != 'user'}">
          {{data.item.name}}
        </span>
      </v-layout>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "ResponsibleSelect",
  props: {
    value: {required: true},
    responsibles: {required: true, type: Array},
    rules: {default: undefined,},
    disabled: {default: false,},
    filled: {default: false},
    dense: {default: false},
    solo: {default: false},
  },
}
</script>