<template>
  <v-card class="elevation-2 mx-1 mt-2">
    <v-card-text>
      <v-expand-transition>
        <div v-if="allowDelete" class="d-flex">
          <v-spacer />
          <v-btn x-small @click="deleteDialog = true">
            Deletar filtro
            <v-icon small class="ml-1">delete_forever</v-icon>
          </v-btn>
        </div>
      </v-expand-transition>
      <div>
        <v-checkbox v-model="filter.filterLocation" class="mt-0" label="Filtrar por Localização" hide-details="auto" />
        <div class="mx-2" v-if="filter.filterLocation">
          <v-autocomplete
            label="País"
            class="mt-4" hide-details="auto" dense
            v-model="filter.country" :items="location" auto-select-first
            item-text="name" item-value="id"
            no-data-text="Nenhuma país encontrado"
            append-icon="arrow_drop_down"
          />
          <v-autocomplete
            label="Estado"
            class="mt-4" hide-details="auto" dense
            v-model="filter.state" :items="states" auto-select-first
            item-text="name" item-value="id"
            no-data-text="Nenhum estado encontrado"
            append-icon="arrow_drop_down"
          />
          <v-autocomplete
            label="Cidade"
            class="mt-4" hide-details="auto" dense
            v-model="filter.city" :items="cities" auto-select-first
            item-text="name" item-value="id"
            no-data-text="Nenhuma cidade encontrada"
            append-icon="arrow_drop_down"
          />
        </div>

        <h4 class="mt-4 mb-1">Categorias</h4>

        <template v-for="(filterCategory, i) in filter.filterCategories">
          <div :key="i" class="d-flex align-center mx-4">
            <v-select
              v-model="filterCategory.category_id"
              label="Categoria" filled dense hide-details="auto" class="flex-grow-1"
              :items="categories" item-text="name" item-value="id"
            />
            <v-select
              v-model="filterCategory.categoryOption_id"
              label="Opção" filled dense hide-details="auto" class="flex-grow-1"
              :items="categoryOptions[i]" item-text="name" item-value="id"
              no-data-text="Selecione primeiro a categoria"
            />
            <v-btn color="red" icon @click="filter.filterCategories.splice(i, 1)">
              <v-icon>clear</v-icon>
            </v-btn>
          </div>
        </template>
        <v-btn class="mx-4 mt-2" small @click="filter.filterCategories.push({})">
          <v-icon class="mr-1">add</v-icon> filtragem
        </v-btn>
      </div>

      <v-autocomplete
        v-model="filter.client" :items="filteredClients" item-text="name" item-value="id"
        label="Cliente (opcional)" class="mx-1 mt-5" dense persistent-hint
        no-data-text="Nenhum cliente encontrado" :hint="`${filteredClients.length-1} clientes filtrados`"
        :loading="loadingClients"
      />
    </v-card-text>

    <v-dialog v-model="deleteDialog" max-width="350px">
      <v-card>
        <v-card-title class="body-2">
          Tem certeza que deseja deletar esse filtro?
        </v-card-title>
        <v-card-actions class="pt-0">
          <v-spacer/>
          <v-btn small @click="deleteDialog = false" color="success" text>
            Não
          </v-btn>
          <v-btn small color="error" text @click="deleteDialog = false; $emit('delete')">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-card>
</template>

<script>
export default {
  name: "TaskFilter",
  props: ['value', 'categories', 'location', 'allowDelete'],
  data(){
    return {
      filter: this.value,
      deleteDialog: false,
      loadingClients: false,
      filteredClients: [],
    };
  },
  computed: {
    states(){
      return this.location.filter(c => c.id == this.filter.country)[0].states;
    },
    cities(){
      let state = this.states.filter(s => s.id == this.filter.state)[0];
      if (!state) return [];
      return state.cities;
    },
    categoryOptions(){
      return this.filter.filterCategories.map((filterCategory) => {
        if (!filterCategory.category_id || this.categories.length == 0) return []
        return this.categories.filter(c => c.id  == filterCategory.category_id)[0].options
      })
    },
  },
  watch: {
    value(v){
      this.filter = v;
    },
    filter: {
      deep: true,
      handler(data){
        this.$emit('input', data)
      },
    },
    'filter.filterCategories': {
      deep: true,
      handler(){
        this.loadFilteredClients()
      },
    },
    'filter.country'(country){
      if (country == -1){
        this.filter.state = -1;
      }
      this.loadFilteredClients()
    },
    'filter.state'(state){
      if (state == -1){
        this.filter.city = -1;
      }
      this.loadFilteredClients()
    },
    'filter.city'(state){
      if (state == -1){
        this.filter.city = -1;
      }
      this.loadFilteredClients()
    },
    'filter.filterLocation'(newVal, oldVal){
      if (oldVal == true && newVal == false){
        this.filter.city = -1
        this.filter.state = -1
        this.filter.country = -1
      }
    },
  },
  methods: {
    loadFilteredClients(){
      this.loadingClients = true
      this.$axios.post('/filter', [this.filter])
        .then((response) => {
          let filteredClients = response.data
          filteredClients.unshift({id: -1, name: "Todos os Clientes Filtrados"})
          this.filteredClients = filteredClients
          this.filter.client = -1
        })
        .finally(() => {
          this.loadingClients = false
        })
    },
  },
  mounted(){
    this.$nextTick(() => {
      this.loadFilteredClients()
    })
  },
};
</script>
