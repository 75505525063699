export default {
  required: val => !!val || "Campo obrigatório.",

  requiredList: val => val.length > 0 || "Campo obrigatório.",

  equalPassword: getPassword => val =>
    val == getPassword() || "As senhas não estão iguais.",

  passwordMin: val =>
    (val != undefined && val.length >= 6) ||
    "A senha deve conter pelo menos 6 dígitos.",

  username: val =>
    /^[a-zç0-9_\.]*$/g.test(val) ||
    "O nome de usuário deve conter somente letras minúsculas sem acento, números e os caracteres '_' e '.'",

  email: val =>
    /^(|[a-z][a-z0-9_\.]*@([a-z][a-z0-9_\.]*)(\.[a-z][a-z0-9_]*))+$/g.test(
      val
    ) || "Insira um e-mail válido.",

  date: val =>
    !val ||
    (/^([0-9]{2}\/){2}[0-9]{4}$/g.test(val) &&
      (parseInt(val.split("/")[0]) <= 31 &&
        parseInt(val.split("/")[1]) <= 12 &&
        parseInt(val.split("/")[2]) > 999)) ||
    "Insira uma data válida.",

  dateRange: getDates => val => {
    if (!val) return true;
    val = val
      .split("/")
      .reverse()
      .join("-");
    return (
      ((!getDates.min || val >= getDates.min) &&
        (!getDates.max || val <= getDates.max)) ||
      "A data de término da subtarefa deve ser antes ou igual ao término da tarefa e no mínimo no dia atual."
    );
  }
};
