<template>
  <div>
    <v-subheader>
      <v-icon small class="mr-2">comment</v-icon>
      Comentários e atualizações
    </v-subheader>

    <div style="max-height: 300px; overflow-y: scroll;">
      <v-form ref="comment" class="ml-4 mr-1 py-1" lazy-validation>
        <v-text-field
          v-model="commentText" outlined hide-details label="Escrever Comentário"
          :loading="loading" :rules="[$rules.required]" dense
        >
          <template #append>
            <v-tooltip top :open-delay="500">
              enviar
              <template #activator="{ on }">
                <v-btn
                  @click="comment()"
                  color="success" fab outlined x-small v-on="on" elevation="0"
                  style="margin-top: -4px"
                  :loading="loading" :disabled="loading"
                >
                  <v-icon small>send</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-form>
      <v-list dense class="mt-0 pt-0">

        <template v-if="subtask.updates.length == 0">
          <v-list-item two-line>
            <v-list-item-content class="my-0">
              <v-list-item-subtitle class="body-2">
                Nenhuma atualização ou comentário nessa subtarefa
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-for="(update, i) in subtask.updates">
          <v-tooltip left :key="i + ' ' + update.id">
            <template v-slot:activator="{ on: tooltip }">
              <v-list-item two-line v-on="tooltip">
                <v-list-item-avatar class="my-0">
                  <v-icon :color="update.option.color">
                    {{ update.option.icon || "comment" }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="my-0">
                  <v-list-item-title>
                    {{ update.author.name || "Comentário" }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="body-2">
                    {{ $utils.parseText( update.text ) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ $utils.timestamp2strDatetime(update.datetime) }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </template>
            <span> {{ update.option.name || "Comentário"}} </span>
          </v-tooltip>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "Updates",
  data(){
    return {
      localSubtask: this.subtask,
      commentText: "",
    };
  },
  props: {
    subtask: { type: Object, required: true },
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    },
    user(){
      return this.$store.getters.getUser;
    },
  },
  watch: {
    subtask(subtask){
      this.localSubtask = subtask;
    },
    localSubtask(subtask){
      this.$emit('update:subtask', subtask);
    }
  },
  methods: {
    comment(){
      if (!this.$refs.comment.validate()) return;
      this.$store.commit('setLoading', true);
      this.$axios.patch('/tasks/subtask/update', {
        subtask_id: this.subtask.id,
        text: this.commentText,
      })
        .then(response => {
          this.$store.commit(
            "showNotificationMessage",
            "Comentário adicionado"
          );
          this.localSubtask.updates.unshift({
            clientSubtask_id: this.subtask.id,
            datetime: new Date().getTime() / 1000,
            text: this.commentText,
            option: {},
            author: this.user,
          });
          this.$refs.comment.reset();
        })
        .catch(error => {
          console.log('error:', error);
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao adicionar comentário"
          );
        })
        .finally(() => {
          this.$store.commit('setLoading', false);
        });
    },
  },
}
</script>

<style lang="css" scoped>
  .v-list-item__subtitle{
    white-space: normal;
  }
</style>
