<template>
  <v-form ref="clientForm">
    <v-layout wrap>
      <v-flex xs12 md6>
        <div :class="{'mr-1': $vuetify.breakpoint.mdAndUp}">
          <v-text-field filled label="Razão Social *" v-model="client.name" :disabled="loading" :rules="[$rules.required]" />
          <v-text-field filled label="Endereço" v-model="client.address" :disabled="loading" />
          <v-text-field filled label="CNPJ" v-model="client.cnpj" mask="##.###.###/####-##" :disabled="loading" return-masked-value />
          <v-text-field filled label="CPF" v-model="client.cpf" mask="###.###.###-##" :disabled="loading" return-masked-value />

          <ListInput
            btnLabel="Telefone Fixo" label="Telefone" mask="(##) ####-####" return-masked-value
            :disabled="loading" v-model="client.phone"
          />

          <ListInput
            btnLabel="Celular" label="Celular" mask="(##) #####-####" return-masked-value
            :disabled="loading" v-model="client.cellphone"
          />
          
        </div>
      </v-flex>
      <v-flex xs12 md6>
        <div :class="{'ml-1': $vuetify.breakpoint.mdAndUp}">
          
          <template v-for="category in categories">
            <DynamicSelect
              :key="category.id"
              v-model="client.categories[category.id]"
              :label="`${category.name} *`" :items="category.options"
              labelNew="Nova Opção" labelName="Nome da Opção *"
              item-value="id" item-text="name"
              :disabled="loading" :rules="[$rules.requiredList]"
              multiple
            />
          </template>
          
          <v-autocomplete
            label="País *" filled
            v-model="client.country_id" :items="location"
            item-value="id" item-text="name"
            :disabled="loading" :rules="[$rules.required]"
          />

          <v-autocomplete
            label="Estado *" filled
            no-data-text="Selecione primeiro um país"
            v-model="client.state_id" :items="states"
            item-value="id" item-text="name"
            :disabled="loading" :rules="[$rules.required]"
          />
          
          <v-autocomplete
            label="Cidade *" filled
            no-data-text="Selecione primeiro um estado"
            v-model="client.city_id" :items="cities"
            item-value="id" item-text="name"
            :disabled="loading" :rules="[$rules.required]"
          />

          <ListInput
            btnLabel="E-Mail" label="E-Mail"
            :disabled="loading" v-model="client.email" :rules="[$rules.email]"
          />

        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-spacer/>
      <v-btn color="success" large @click="submit" :loading="loading">
        {{submitText}}
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import { ListInput, DynamicSelect } from "@/components/pages/forms/inputs";
export default {
  name: "ClientForm",
  components: { ListInput, DynamicSelect },
  data: function() {
    return {

      categoryDialog: false,
      categoryName: "",

      taxationDialog: false,
      taxationName: "",

      countryDialog: false,
      countryName: "",

      stateDialog: false,
      stateName: "",

      cityDialog: false,
      cityName: "",

      categories: [],
      taxations: [],
      location: [],

      selectedCountry: null,
      selectedState: null,

      client: this.value
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    },
    states(){
      let country = this.location.filter(c => c.id == this.client.country_id)[0];
      if (!country) return [];
      return country.states;
    },
    cities(){
      let state = this.states.filter(s => s.id == this.client.state_id)[0];
      if (!state) return [];
      return state.cities;
    },
  },
  props: ["value", "submitText"],
  watch: {
    client(value) {
      this.$emit("input", value);
    },
    value(val){
      this.client = val;
    }
  },
  methods: {
    submit(e) {
      if (this.$refs.clientForm.validate()) {
        this.$emit("submit", e);
      }
    },
    reset() {
      this.$refs.clientForm.reset();
    }
  },
  created() {
    this.$store.commit("setLoading", true);
    this.$axios.get('/clients/data')
      .then(response => {
        this.$store.commit("setLoading", false);
        this.categories = response.data.categories;
        this.location = response.data.location;
      })
      .catch(error => {
        this.$store.commit("setLoading", false);
        this.$store.commit(
          "showNotificationMessage",
          "Erro ao buscar dados de clientes"
        );
        this.$router.go(-1);
      });
  }
};
</script>
