import Vue from "vue";
import Vuex from "vuex";
import { sync } from 'vuex-router-sync';
import VueTheMask from "vue-the-mask";
import vuetify from './plugins/vuetify';
import App from "./App";
import router from "./router";
import store from "@/store";
import utils from '@/utils.js';
import rules from '@/rules.js';

Vue.use(Vuex);
Vue.use(VueTheMask)

Vue.config.productionTip = false;


var axios = require('axios').create({
  baseURL: process.env.NODE_ENV == 'production'
            ? 'https://api.tarefas.cnt.br'
            : 'http://localhost:8000',
	withCredentials: true,
});

axios.interceptors.response.use(
  (response) => {
    store.commit('updateUser');
    return response;
  },
  (error) => {
    if (!error || !error.response){
      return Promise.reject(error);
    }
    let status = error.response.status;
    if ([401, 403].includes(status)){
      store.commit('logout');
    }
    return Promise.reject(error);
  }
);


Vue.prototype.$axios = axios;
Vue.prototype.$utils = utils;
Vue.prototype.$rules = rules;
sync(store, router);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
