<template>
  <v-dialog v-model="showing" max-width="800px" :persistent="loading">
    <v-card>
      <v-card-title>
        Inclusão de cliente na tabela <v-chip label class="ml-2">{{ task.name }}</v-chip>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-autocomplete
            label="Cliente *"
            v-model="newClientTask.client_id"
            :items="clients" item-text="name" item-value="id"
            :rules="[$rules.required]"
            :loading="loadingClients"
            :disabled="loading"
            no-data-text="Nenhum cliente encontrado"
          />
          <ResponsibleSelect
            v-if="task.defineClientTaskResponsible"
            v-model="newClientTask.responsible"
            :rules="[$rules.required]"
            :responsibles="responsibles"
            :loading="loadingUsers"
            :disabled="loading"
          />
        </v-form>
        <v-alert v-model="alreadyIncludedError" type="warning" class="mt-4" transition="scale-transition">
          Esse cliente já está incluído na tabela
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="loading" @click="showing = false">
          Cancelar
        </v-btn>
        <v-btn color="success" :loading="loading" @click="includeClientTask()">
          Incluir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ResponsibleSelect from '@/components/pages/forms/inputs/ResponsibleSelect'

export default {
  name: "ClientSelectDialog",
  components: { ResponsibleSelect },
  props: {
    task: {required: true},
  },
  data: () => ({
    showing: false,
    clients: [],
    responsibles: [],
    loadingClients: false,
    loadingUsers: false,
    loading: false,
    alreadyIncludedError: false,
    newClientTask: {
      client_id: undefined,
      responsible: "*",
    },
  }),
  methods: {
    open(){
      if (this.clients.length == 0){
        this.fetchClientsData()
        this.fetchUsersData()
      }
      this.showing = true
      this.alreadyIncludedError = false
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
    fetchClientsData() {
      this.loadingClients = true
      this.$axios.post('/filter', [])
        .then((response) => {
          this.clients = response.data
        })
        .finally(() => {
          this.loadingClients = false
        })
    },
    fetchUsersData() {
      this.loadingUsers = true
      this.$axios.get('/tasks/users')
        .then(response => {
          let sectors = response.data;
          let responsibles = [{ name: 'Todos os Usuários', id: '*' }];
          sectors.forEach((sector) => {
            responsibles.push({
              name: sector.name,
              id: `sector/${sector.id}`,
              type: 'sector',
            });
            sector.users.forEach((user) => {
              responsibles.push({
                name: user.name,
                id: `user/${user.id}`,
                type: 'user',
              });
            });
          });
          this.responsibles = responsibles;
          this.loadingUsers = false
        })
    },
    includeClientTask(){
      this.alreadyIncludedError = false
      if (this.$refs.form.validate()){
        this.loading = true
        this.$axios.post('/task/client-task', {
          task_id: this.task.id,
          ...this.newClientTask,
        })
        .then((response) => {
          this.$refs.form.reset()
          this.$store.commit(
            'showNotificationMessage',
            "Cliente incluído na tabela com sucesso"
          );
          this.showing = false
          this.$emit('clientTaskCreated')
        })
        .catch((error) => {
          if (error.response){
            if (error.response.status == 400){
              this.alreadyIncludedError = true
              return
            }
          }
          this.$store.commit(
            'showNotificationMessage',
            "Erro inesperado ao incluir cliente na tabela"
          );
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>
