<template>
  <div>
    <PageBreadcrumbs :items="[['Clientes','/clients'], ['Novo Cliente','/clients/new']]" />

    <v-card class="mt-1">
			<v-card-title>
        
			<v-btn icon @click="$router.go(-1)" :disabled="loading">
				<v-icon>arrow_back</v-icon>
			</v-btn>

			<div class="title">
          Novo Cliente
			</div>
			
      </v-card-title>
			<v-card-text>
				<ClientForm
          ref="clientForm"
          v-model="client"
          submitText="Cadastrar"
          @submit="registerClient"
        />
      </v-card-text>

    </v-card>
	</div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import ClientForm from "./ClientForm";

export default {
  name: "NewClient",
  components: { PageBreadcrumbs, ClientForm },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  },
  data() {
    return {
      client: {
        name: "",
        address: "",
        phone: [""],
        cellphone: [""],
        email: [""],
        cnpj: "",
        cpf: "",
        categories: {},
        taxation: "",
        country_id: "",
        state_id: "",
        city_id: null,
      }
    };
  },
  methods: {
    registerClient() {
      this.$store.commit("setLoading", true);
      this.$axios.post('/clients', this.client)
        .then(response => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Cliente cadastrado com sucesso"
          );
          this.$refs.clientForm.reset();
          this.$router.go(-1);
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao cadastrar cliente"
          );
        });
    }
  }
};
</script>
