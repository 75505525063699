<template>
	<v-form ref="taskSubtasksForm">
		<span class="headline">Criação de Subtarefas</span>
		<v-divider class="mt-1 mb-1" />

    <h4 class="grey--text text--darken-2">
      Opções de Estado das Subtarefas
    </h4>

    <v-simple-table dense class="mx-1 elevation-2" fixed-header>
      <thead>
        <th class="text-center">Nome</th>
        <th class="text-center">Ícone</th>
        <th class="text-center">Cor</th>
        <th class="text-center">Indica Feito</th>
        <th class="text-center">Indica Término</th>
        <th/>
      </thead>
      
      <draggable v-model="task.updateOptions" draggable=".updateOption" :animation="150" tag="tbody">
        <tr v-for="(updateOption, i) in task.updateOptions" :key="i" class="updateOption">
          <td class="py-1">
            <v-text-field v-model="updateOption.name" dense label="Nome" solo hide-details="auto" />
          </td>
          <td>
            <v-menu max-width="200px">
              <template #activator="{ on }">
                <div class="d-flex justify-center align-center">
                  <v-btn icon v-on="on">
                    <v-icon>{{ updateOption.icon }}</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-card class="pa-2">
                <template v-for="ic in icons">
                  <v-btn icon :key="ic" @click="updateOption.icon = ic">
                    <v-icon>{{ ic }}</v-icon>
                  </v-btn>
                </template>
              </v-card>
            </v-menu>
          </td>
          <td>
            <v-menu>
              <template #activator="{ on }">
                <div class="d-flex justify-center align-center">
                    <v-btn :color="updateOption.color" v-on="on" small />
                </div>
              </template>

              <v-color-picker
                v-model="updateOption.color"
                hide-canvas hide-sliders hide-inputs hide-mode-switch
                show-swatches
              />
            </v-menu>
          </td>
          <td>
            <div class="d-flex justify-center align-center">
              <v-checkbox v-model="updateOption.is_done" class="py-0 my-0 ml-2" hide-details="auto" />
            </div>
          </td>
          <td>
            <div class="d-flex justify-center align-center">
              <v-checkbox v-model="updateOption.is_final" class="py-0 my-0 ml-2" hide-details="auto" />
            </div>
          </td>
          <td>
            <v-btn color="warning" icon @click="task.updateOptions.splice(i, 1)">
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </draggable>
      <tbody>
        <tr> <td colspan="6" style="height: 0" /></tr>
        <tr>
          <td colspan="6">
            <v-btn class="my-2" color="green lighten-2" small block @click="createNewUpdateOption()">
              Novo Estado
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

		<v-layout wrap>
			<v-layout wrap>
				<v-flex v-for="(subtask, i) in subtasks" :key="i" sm12 md6>
					<v-card class="mx-1 mt-2 pa-3 elevation-2">
							<v-layout>

                <!-- name -->
                <v-flex grow>
                  <v-text-field
                    ref="subtaskName"
                    v-model="subtasks[i].name" :rules="subtask.deleted ? []:[$rules.required]"
                    label="Nome da Subtarefa *" filled
                    hide-details="auto"
                    :disabled="disabled || subtask.deleted"
                  >
                    <template slot="prepend-inner">
                      <span class="title mr-2" style="margin-top: -5px">
                        #{{i+1}}
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>

								<!-- delete and move -->
								<v-flex class="mt-1" shrink>
                  <v-menu left bottom>
                    <template v-slot:activator="{on}">
                      <v-btn icon small v-on="on" class="mt-1" :disabled="subtask.deleted">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout align-center>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on" icon small class="mx-0"
                              :disabled="task.subtasks.length == 1"
                              @click="$set(subtasks[i], 'deleted', true)"
                            >
                              <v-icon>delete_forever</v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar Subtarefa</span>
                        </v-tooltip>

                        <v-btn :disabled="i == 0" @click="subtaskLeft(i)" icon small class="mx-0">
                          <v-icon>chevron_left</v-icon>
                        </v-btn>
                        <v-btn :disabled="i == subtasks.length-1" @click="subtaskRight(i)" icon small class="mx-0">
                          <v-icon>chevron_right</v-icon>
                        </v-btn>
                      </v-layout>
                    </v-card>
                  </v-menu>
								</v-flex>

                <v-overlay absolute v-if="subtask.deleted">
                  <v-chip color="warning" class="mt-1 mx-3 elevation-2">
                    Subtarefa deletada
                    <v-tooltip top>
                      desfazer
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" color="error" class="ml-2" fab small style="margin-right: -12px">
                          <v-icon @click="subtasks[i].deleted = false">undo</v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </v-chip>
                </v-overlay>

							</v-layout>

							<!-- deadline -->
							<v-menu
                :value="openDatePicker == i" @input="openDatePicker = $event ? i:-1"
                :close-on-content-click="false" max-width="300px"
                :disabled="disabled || subtask.deleted"
              >
                <template v-slot:activator="{on}">
                  <v-text-field
                    v-if="!task.pattern"
                    ref="subtaskDeadline"
                    v-model="subtasks[i].deadline"
                    v-on="on" label="Data de Término"
                    filled prepend-inner-icon="date_range"
                    :disabled="disabled || subtask.deleted"
                    v-mask="['##/##/####']" return-masked-value
                    :rules="subtask.deleted ? []:[$rules.date,$rules.dateRange({
                      min: new Date().toISOString().substr(0, 10),
                      max: task.deadline ? task.deadline.split('/').reverse().join('-'):''
                    })]"
                    hide-details="auto"
                  />
                </template>
								<v-date-picker
									@input="selectedDate($event, i)"
									no-title full-width
									:min="new Date().toISOString().substr(0, 10)"
									:max="task.deadline ? task.deadline.split('/').reverse().join('-'):''"
									:show-current="false"
                  next-icon="chevron_right"
                  prev-icon="chevron_left"
                />
							</v-menu>

							<!-- description -->
							<v-textarea
                :ref="`subtask_${i}`"
                :value="subtasks[i].description"
                @input="subtasks[i].description = $event"
                label="Descrição" filled rows="1" auto-grow
                hide-details="auto"
                :disabled="disabled || subtask.deleted"
                />

							<!-- links -->
							<template v-for="(link, j) in subtasks[i].links">
								<v-card class="elevation-2 mb-0 mt-2 pa-1" :key="j">
								<v-layout>

									<v-text-field
                    label="Identificação"
                    class="" outlined hide-details
                    v-model="subtasks[i].links[j].identification"
                    :disabled="disabled || subtask.deleted"
                    />
									<v-text-field
                    label="Link"
                    class="ml-1" outlined hide-details
                    v-model="subtasks[i].links[j].link"
                    hint="Atenção! Esse deve ser um link válido"
                    :disabled="disabled || subtask.deleted"
                    />
									<v-btn color="error" icon small class="mt-2 mr-0" @click="subtasks[i].links.splice(j,1)" :disabled="disabled || subtask.deleted">
										<v-icon>delete</v-icon>
									</v-btn>
									<v-flex shrink>
										<v-layout column class="mt-1 mr-2">
												<v-icon
                          :disabled="j == 0 || disabled || subtask.deleted"
                          @click="moveLink(i, j, j-1)"
                        >
                          keyboard_arrow_up
                        </v-icon>
												<v-icon
                          :disabled="j == subtasks[i].links.length-1 || disabled || subtask.deleted"
												  @click="moveLink(i, j, j+1)"
                        >
                          keyboard_arrow_down
                        </v-icon>
										</v-layout>
									</v-flex>
								</v-layout>
								</v-card>
							</template>
							<v-layout>
								<v-spacer />
								<v-btn color="primary" small class="my-1" @click="addLink(i)" :disabled="disabled || subtask.deleted">
									<v-icon>add</v-icon> link
								</v-btn>
							</v-layout>

              <ResponsibleSelect
                v-model="subtasks[i].responsible"
                :disabled="disabled || subtask.deleted"
                :rules="subtask.deleted ? []:[$rules.required]"
                :responsibles="responsibles"
                filled
              />
					</v-card>
				</v-flex>
			</v-layout>
			<v-flex xs12>
				<v-layout>
					<v-spacer />
					<v-btn @click="newSubtask()" color="info" class="elevation-4 mt-2">
						<v-icon>add</v-icon>
						subtarefa
					</v-btn>
				</v-layout>
			</v-flex>
		</v-layout>

	</v-form>
</template>

<script>
import draggable from 'vuedraggable'
import ResponsibleSelect from './ResponsibleSelect';

export default {
  name: "TaskSubtasks",
  components: {ResponsibleSelect, draggable},
  props: ["value", "disabled", "cameBack", "isCreatingTask", "createInitialSubtask"],
  data() {
    return {
      subtasks: [],
      task: this.value,
      responsibles: [],
      openDatePicker: -1,
      icons: [
        'done', 'add_task', 'check_box', 'beenhere', 'verified', 'thumb_up_alt', 'security',
        'update', 'refresh',
        'remove_circle',  'delete', 'timer', 'report', 'warning', 'cancel',
        'pending', 'sms',
        'star', 
        'emoji_objects', 'construction',
        'moped', 'flight', 'bedtime',
        'headset', 'monetization_on',        
      ],
    };
  },
  watch: {
    value(val) {
      this.task = val
      this.subtasks = val.subtasks
    },
    'task.sector_id'(new_sector_id, old_sector_id){
      if (new_sector_id != old_sector_id){
        this.subtasks.forEach((subtask) => {
          if (subtask.responsible.endsWith('undefined') || !subtask.responsible){
            subtask.responsible = 'sector/' + new_sector_id
          }
        })
      }
    },
    task(val) {
      this.$emit("input", val);
    },
    subtasks: {
      deep: true,
      handler() {
        var subtasks = this.subtasks.filter(subtask => !subtask.deleted);
        for (var i = 0; i < subtasks.length; ++i) {
          delete subtasks[i].deleted;
        }
        this.task.subtasks = subtasks;
      }
    }
  },
  computed: {
    deadlineDate() {
      var deadline = this.task.deadline.split("/");
      return new Date(`${deadline[2]}-${deadline[1]}-${deadline[0]}`);
    }
  },
  methods: {
    validate() {
      return this.$refs.taskSubtasksForm.validate()
    },
    selectedDate(date, subtaskIndex) {
      this.openDatePicker = -1
      const [year, month, day] = date.split("-")
      this.subtasks[subtaskIndex].deadline = `${day}/${month}/${year}`
    },
    newSubtask() {
      var subtask = {
        name: "",
        description: "",
        responsible: 'sector/' + this.task.sector_id,
        deadline: "",
        links: [],
        deleted: false
      };
      if (this.task.pattern) {
        delete subtask["deadline"];
      }
      this.subtasks.push(subtask);
    },
    swapSubtasks(i, j) {
      const aux = this.subtasks[j];
      this.$set(this.subtasks, j, this.subtasks[i]);
      this.$set(this.subtasks, i, aux);
    },
    subtaskLeft(index) {
      this.swapSubtasks(index, index - 1);
    },
    subtaskRight(index) {
      this.swapSubtasks(index, index + 1);
    },
    addLink(subtaskIndex) {
      this.subtasks[subtaskIndex].links.push({
        identification: "",
        link: ""
      });
    },
    moveLink(subtaskIndex, linkIndex, newIndex) {
      var old = this.subtasks[subtaskIndex].links[linkIndex];
      var newI = this.subtasks[subtaskIndex].links[newIndex];
      this.$set(this.subtasks[subtaskIndex].links, newIndex, old);
      this.$set(this.subtasks[subtaskIndex].links, linkIndex, newI);
    },
    fetchUsersData() {
      this.$store.commit("setLoading", true);
      this.$axios.get('/tasks/users')
        .then(response => {
          let sectors = response.data;
          let responsibles = [{ name: 'Todos os Usuários', id: '*' }];
          sectors.forEach((sector) => {
            responsibles.push({
              name: sector.name,
              id: `sector/${sector.id}`,
              type: 'sector',
            });
            sector.users.forEach((user) => {
              responsibles.push({
                name: user.name,
                id: `user/${user.id}`,
                type: 'user',
              });
            });
          });
          this.responsibles = responsibles;
          if (this.isCreatingTask && this.createInitialSubtask){
            this.newSubtask();
          }
        })
        .catch(error => {
          this.$emit("error");
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        })
    },
    createNewUpdateOption(){
      this.task.updateOptions.push({
        name: "",
        icon: "done",
        color: "green",
        is_final: false,
        is_done: false,
      })
    },
  },
  mounted() {
    this.fetchUsersData();
    if (this.task.updateOptions.length == 0){
      this.createNewUpdateOption()
    }
  }
};
</script>
