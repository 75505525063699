<template>
  <div>
    <v-btn
      ref="actionButton"
      icon small
      @click="
        computeMenuPosition();
        optionsMenu = true;
      "
      :disabled="loading"
    >
      <v-icon small>mdi-dots-vertical</v-icon>
    </v-btn>
    <v-menu
      v-model="optionsMenu"
      left
      :position-x="menuPosition.x"
      :position-y="menuPosition.y"
    >
      <v-list dense>
        <v-list-item
          @click="updateMenu = true"
        >
          <v-list-item-title>
            Marcar subtarefas do cliente em aberto
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{
            name: 'ClientTask',
            params: {
              task: task_id,
              client: clientTask.client.id
            }
          }"
        >
          <v-list-item-title>
            Ir para tarefa do cliente
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      v-model="updateMenu"
      left min-width="200px"
      :close-on-content-click="false"
      :position-x="menuPosition.x"
      :position-y="menuPosition.y"
    >
      <UpdateSelector
        ref="updateSelector"
        :updateOptions="updateOptions"
        :loading="loading"
        @close="updateMenu = false"
        :clientTask_id="clientTask.id"
        @added-update="addUpdate"
      />
    </v-menu>
  </div>
</template>

<script>
import UpdateSelector from './UpdateSelector.vue';

export default {
  name: "TableRowOptions",

  props: {
    task_id: {type: Number, required: true},
    updateOptions: {type: Array, required: true},
    loading: {type: Boolean, default: false},
    clientTask: {type: Object, required: true},
  },

  components: {
    UpdateSelector,
  },

  data: () => ({
    optionsMenu: false,
    updateMenu: false,
    menuPosition: {},
  }),

  methods: {
    computeMenuPosition(){
      let actionButton = this.$refs.actionButton;
      if (actionButton){
        this.menuPosition = actionButton.$el.getBoundingClientRect();
      }
    },
    addUpdate(newUpdate){
      this.$emit("update:clientTask", {
        ...this.clientTask,
        subtasks: this.clientTask.subtasks.map(subtask => ({
          ...subtask,
          updates: [
            ...(subtask.updates.length == 0 ? [newUpdate]:[]),
            ...subtask.updates,
          ]
        })),
      });
    },
  },


  watch: {
    updateMenu(current, old){
      if (!current && old){
        this.$refs.updateSelector.cancel();
      }
    },
  },

}
</script>
