<template>  
  <v-card>
    <div class="d-flex" :class="{'flex-column': allowUpdate}">
      <v-list dense :nav="!haveOptionSelected" v-if="allowUpdate" class="px-0">
        <v-subheader>
          Marcar como:
          <v-spacer />
          <v-btn
            class="ml-2" float icon small
            style="margin-right: -8px"
            @click="$emit('close')"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-subheader>
        <v-list-item-group>
          <v-slide-x-transition group>
            <template v-for="option in updateOptions">
              <v-list-item
                :key="option.id"
                @click="selectOption(option)"
                v-if="!haveOptionSelected || optionSelected.id == option.id"
                :class="{'grey lighten-3': optionSelected.id == option.id}"
              >
                <v-list-item-icon>
                  <v-icon :style="{'color': option.color}">
                    {{ option.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{option.name}}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-slide-x-transition>

          <v-expand-transition>
            <div v-if="showCommentAndSend" class="mt-2 mx-2 d-flex align">
              <v-text-field
                v-model="updateText" class="flex-grow-1"
                label="Comentário opcional"
                solo hide-details
                :loading="loading"
              />
              <v-btn
                outlined color="success"
                height="48px" class="ml-1 elevation-2"
                @click="sendUpdate()"
                :loading="loading"
              >
                <v-icon>near_me</v-icon>
              </v-btn>
            </div>
          </v-expand-transition>

        </v-list-item-group>
      </v-list>
      <div v-else shrink>
        <div style="width: 60px">
          <div class="caption grey--text text--darken-3" style="
            position: absolute;
            width: 150px;
            top: 50%;
            left: 30px;
            text-align: center;
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
          ">
            Você <strong>não</strong> é responsável por essa subtarefa
          </div>
        </div>
      </div>
      <template v-if="withUpdates && !haveOptionSelected && subtask != undefined">
        <v-divider
          class="grey lighten-1"
          :class="{'mt-3': allowUpdate}"
          :vertical="!allowUpdate"
        />
        <Updates :subtask.sync="localSubtask" />
      </template>
    </div>
  </v-card>
</template>

<script>
import { setTimeout } from 'timers';
import Updates from "@/components/pages/task_items/Updates";

export default {
  name: "UpdateSelector",
  
  props: {
    updateOptions: {type: Array, required: true},
    subtask: {type: Object},
    clientTask_id: {type: Number, default: undefined},
    loading: {type: Boolean, default: false},
    withUpdates: {type: Boolean, default: false},
  },

  components: {
    Updates,
  },

  data(){
    return {
      localSubtask: this.subtask,
      optionSelected: {id: -1},
      updateText: "",
      showCommentAndSend: false,
    };
  },

  watch: {
    subtask(val) {
      this.localSubtask = val;
    },
    localSubtask(val) {
      this.$emit("update:subtask", val);
    },
  },

  computed: {
    haveOptionSelected(){
      return (this.optionSelected.id != -1);
    },
    allowUpdate(){
      return (this.subtask == undefined || this.subtask.userIsResponsible);
    },
  },

  methods: {
    cancel(){
      this.optionSelected = {id: -1};
      this.showCommentAndSend = false;
    },
    selectOption(option){
      this.optionSelected = option;
      setTimeout(() => {
        this.showCommentAndSend = true;
      }, 400);
    },
    sendUpdate(){
      this.$store.commit("setLoading", true);
      this.$axios.patch('/tasks/subtask/update', {
        subtask_id: (this.subtask || {}).id,
        clientTask_id: this.clientTask_id,
        option_id: this.optionSelected.id,
        text: this.updateText,
      })
        .then(response => {
          this.$store.commit(
            "showNotificationMessage",
            "Subtarefa marcada como " + this.optionSelected.name
          );
          this.$emit('close');
          this.$nextTick(() => {
            this.updateText = "";
            this.optionSelected = {id: -1};
            this.showCommentAndSend = false;
          });
          if (this.subtask){
            this.localSubtask.updates.unshift({
              clientSubtask_id: this.subtask.id,
              datetime: new Date().getTime() / 1000,
              text: this.updateText,
              author: this.$store.getters.getUser,
              option: this.optionSelected,
            });
          } else {
            this.$emit('added-update', {
              datetime: new Date().getTime() / 1000,
              text: this.updateText,
              author: this.$store.getters.getUser,
              option: this.optionSelected,
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao alterar estado da subtarefa"
          );
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        })
    },
  },

}
</script>
