<template>
	<div>
    <PageBreadcrumbs :items="[
      ['Minhas Tarefas','/tasks'],
      ['Padrões','/tasks/patterns'],
      ['Usar Tarefa Padrão',`/tasks/pattern/use/${patternID}`]
    ]" />

    <TaskForm
      ref="taskForm"
      v-model="task"
      @finalize="createClientTasks"
      :loading="loading"
      :isCreatingTask="false"
      :createInitialSubtask="false"
    />

	</div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import TaskForm from "./TaskForm";

export default {
  name: "CreateTaskFromPattern",
  components: { PageBreadcrumbs, TaskForm },
  data() {
    return {
      patternID: this.$route.params.id,
      task: {
        name: "",
        description: "",
        deadline: "",
        highPriority: false,
        filters: [],
        subtasks: [],
        updateOptions: [],
      }
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  },
  methods: {
    fetchPatternTask() {
      this.$store.commit("setLoading", true);
      this.$axios.get(`/tasks/patterns/${this.patternID}`)
        .then(response => {
          let task = response.data;
          task.pattern = false
          task.defineClientTaskResponsible = false
          task.deadline = ""
          task.highPriority = false
          this.task = task
        })
        .catch(error => {
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao buscar dados da tarefa padrão"
          );
          this.$router.go(-1);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        })
    },
    createClientTasks() {
      this.reachedStep = 4;
      if (this.$refs.taskForm.validate()) {
        this.$store.commit("setLoading", true);
        var task = Object.assign({}, this.task);
        delete task.pattern;
        this.$axios.post('/tasks/new', task)
          .then(response => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Tarefa padrão utilizada com sucesso"
            );
            this.$router.go(-1);
          })
          .catch(error => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Erro ao utilizar tarefa padrão"
            );
          });
      }
    }
  },
  created() {
    this.fetchPatternTask();
  }
};
</script>
