<template>
    <div>
        <v-btn color="info" small block @click="list.push('')" :disabled="disabled">
            <v-icon>add</v-icon> {{btnLabel}}
        </v-btn>
        <template v-for="(item, index) in list">
            <v-text-field
            filled :label="label"
            :key="index" v-model="list[index]"
            :mask="mask" :disabled="disabled"
            :return-masked-value="returnMaskedValue"
            :rules="rules"
            >
                <template slot="append">
                    <v-btn icon small class="my-0"
                        @click="list.splice(index, 1)" :disabled="disabled"
                    >
                        <v-icon color="error">delete</v-icon>
                    </v-btn>
                    <v-layout column class="py-1" style="margin-top: -16px;">
                        <v-btn icon small class="ma-0 pa-0"
                            @click="swap(index, index-1)"
                            :disabled="index == 0"
                        >
                            <v-icon>keyboard_arrow_up</v-icon>
                        </v-btn>
                        <v-btn icon small class="ma-0 pa-0"
                            @click="swap(index, index+1)"
                            :disabled="index == list.length-1"
                        >
                            <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                    </v-layout>
                </template>
            </v-text-field>
        </template>
    </div>
</template>

<script>
export default {
  name: "ListInput",
  props: [
    "btnLabel",
    "label",
    "mask",
    "disabled",
    "value",
    "rules",
    "return-masked-value"
  ],
  data() {
    return {
      list: this.value
    };
  },
  watch: {
    value(val) {
      this.list = val;
    },
    list(list) {
      this.$emit("input", list);
    }
  },
  methods: {
    swap(i1, i2) {
      var a = this.list[i1];
      var b = this.list[i2];
      this.$set(this.list, i1, b);
      this.$set(this.list, i2, a);
    }
  }
};
</script>
