<template>
    <div>
        Página Inicial
    </div>
</template>

<script>
export default {
  name: "Home"
};
</script>
