<template>
	<div>
		<PageBreadcrumbs :items="[
      ['Minhas Tarefas','/tasks'],
      [`${task.name}`,`/tasks/task/${taskID}`]
    ]" />

    <ClientSelectDialog
      ref="clientSelectDialog"
      :task="task"
      @clientTaskCreated="loadTask()"
    />

    <ChangeResponsible
      ref="changeResponsible"
      :task="task"
      @responsibleChanged="loadTask()"
    />

		<v-card class="mt-2">
			<v-toolbar flat class="py-2 mt-1">
				<v-text-field
					class="mt-4"
					label="Busca por Clientes"
					clearable color="success"
					prepend-inner-icon="search"
					v-model="searchQueryLocal"
          @keyup.enter="searchQuery = searchQueryLocal"
					:disabled="loading"
				/>
        <v-btn color="primary" small class="ml-4" :disabled="loading" @click="$refs.clientSelectDialog.open()">
          Incluir Cliente
        </v-btn>
			</v-toolbar>
      <div v-if="task.defineClientTaskResponsible" class="d-flex align-center mx-4 py-2">
        <span class="body-2 mr-2">
          Filtrar por responsável:
        </span>
        <ResponsibleSelect
          v-model="responsible" :responsibles="responsibles"
          :disabled="loading"
          solo dense
        />
      </div>
      <v-sheet height="4px">
        <v-progress-linear
          indeterminate
          v-show="loading"
        />
      </v-sheet>
      <CustomTable
        ref="dataTable"
        :itemsLength="task.length"
        itemsText="tarefas de clientes"
      >
        <template #thead>
          <th class="text-left pl-4 py-2"> Cliente </th>
          <th v-if="task.defineClientTaskResponsible" class="text-left pl-4">
            Responsável
          </th>
          <th
            v-for="subtask in task.subtasks"
            :key="subtask.id"
            class="text-center"
          >
            {{subtask.name}}
          </th>
        </template>
				<template #tbody>
          <tr v-for="(clientTask, i) in task.clientTasks" :key="clientTask.id">
            <td>
              <div class="d-flex align-center">
                <div class="flex-grow-1">
                  {{clientTask.client.name}}
                </div>
                <TableRowOptions
                  :task_id="task.id"
                  :clientTask.sync="task.clientTasks[i]"
                  :updateOptions="task.updateOptions"
                  :loading="loading"
                />
              </div>
            </td>
            <td v-if="task.defineClientTaskResponsible">
              <div
                v-ripple style="cursor: pointer" class="text-left"
                @click="$refs.changeResponsible.open(clientTask)"
              >
                <template v-if="clientTask.responsible.type == 'user'">
                  {{ clientTask.responsible.user.name }}
                </template>
                <template v-else-if="clientTask.responsible.type == 'sector'">
                  {{ clientTask.responsible.sector.name }}
                </template>
                <template v-else>
                  Todos
                </template>
              </div>
            </td>
            <template v-for="(subtask, index) in clientTask.subtasks">
              <td :key="subtask.name">
                <v-layout align-center justify-center>
                  <SubtaskCheckbox
                    :updateOptions="task.updateOptions"
                    :subtask.sync="clientTask.subtasks[index]"
                    :loading="loading"
                    withUpdates
                  />
                </v-layout>
              </td>
            </template>
          </tr>
				</template>
      </CustomTable>
		</v-card>
	</div>
</template>

<script>
import { PageBreadcrumbs, CustomTable, ClientSelectDialog, ChangeResponsible } from "@/components/core";
import SubtaskCheckbox from "@/components/pages/task_items/SubtaskCheckbox";
import ResponsibleSelect from './forms/inputs/ResponsibleSelect.vue';
import TableRowOptions from './task_items/TableRowOptions.vue';

export default {
  name: "Task",
  components: {
    PageBreadcrumbs,
    SubtaskCheckbox,
    ClientSelectDialog,
    CustomTable,
    ResponsibleSelect,
    ChangeResponsible,
    TableRowOptions,
  },
  data() {
    return {
      searchQueryLocal: this.$route.params.searchQuery,
      searchQuery: this.$route.params.searchQuery,
      task: {
        length: 0,
        subtasks: [],
        clientTasks: [],
      },
      clientSubtasksStatus: [],
      responsible: '*',
      responsibles: [],
      timeout: undefined,
    };
  },
  computed: {
    taskID() {
      return this.$route.params.task;
    },
    loading() {
      return this.$store.getters.isLoading;
    },
    filteredClientTasks(){
      this.$nextTick(() => {
        this.$refs.dataTable.update();
      });
      let clientsTasks = this.task.clientTasks;
      // filter by responsible
      if (this.responsible != '*'){
        let [type, id] = this.responsible.split('/')
        clientsTasks = clientsTasks.filter((clientTask) => {
          if (clientTask.responsible.type == type){
            return clientTask.responsible[type].id == id
          }
        })
      }
      // filter by search query
      if (!this.searchQuery) return clientsTasks
      return this.$utils.filterData(this.searchQuery, clientsTasks);
    },
  },
  watch: {
    searchQueryLocal(txt){
      if (this.timeout){
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.searchQuery = txt;
      }, 600);
    },
    searchQuery(txt){
      let params = {
        task: this.$route.params.task,
        pageSize: this.$route.params.pageSize || 10,
        page: 1,
      };
      if (txt) params.searchQuery = txt;
      this.$router.replace({
        name: "Task",
        params,
      });
    },
    responsible(){
      this.loadTask();
    },
    '$route.params'(){
      this.loadTask();
    },
  },
  methods: {
    loadResponsibles(){
      this.$axios.get('/tasks/users')
        .then(response => {
          let sectors = response.data;
          let responsibles = [{ name: 'Todos os Usuários', id: '*' }];
          sectors.forEach((sector) => {
            responsibles.push({
              name: sector.name,
              id: `sector/${sector.id}`,
              type: 'sector',
            });
            sector.users.forEach((user) => {
              responsibles.push({
                name: user.name,
                id: `user/${user.id}`,
                type: 'user',
              });
            });
          });
          this.responsibles = responsibles;
        })
    },
    loadTask(){
      const pageSize = parseInt(this.$route.params.pageSize) || 10;
      const page = parseInt(this.$route.params.page) || 1;
      if (pageSize == -1 && page > 1){
          let params = this.$route.params;
          params.page = 1;
          this.$router.replace({
            name: this.$route.name,
            params,
          });
          return;
      }
      this.$store.commit('setLoading', true);
      this.$axios.get(`/tasks/${this.taskID}`, {
        params: {
          search_query: this.searchQuery,
          limit: pageSize,
          offset: (page-1) * pageSize,
          responsible: this.responsible,
        },
      })
        .then(response => {
          this.task = response.data;
          this.$refs.dataTable.update();
          let params = this.$route.params;
          if (params.page > 1 && params.page > Math.ceil(this.task.length/params.pageSize)){
            params.page = 1;
            this.$router.replace({
              name: this.$route.name,
              params,
            });
          }
        })
        .catch(error => {
          this.$store.commit(
            'showNotificationMessage',
            "Erro ao buscar dados da tarefa"
          );
          this.$router.go(-1);
        })
        .finally(() => {
          this.$store.commit('setLoading', false);
        });
    },
  },
  mounted() {
    this.loadTask()
    this.loadResponsibles()
  }
};
</script>
