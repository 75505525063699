<template>
  <div>
    <PageBreadcrumbs :items="[['Clientes','/clients'], ['Edição de Cliente',`/clients/edit/${$route.params.id}`]]" />

    <v-card class="mt-1">
			<v-card-title>
        
			<v-btn icon @click="$router.go(-1)" :disabled="loading">
				<v-icon>arrow_back</v-icon>
			</v-btn>

			<div class="title">
				Edição de Cliente 
			</div>

      </v-card-title>
			<v-card-text>
				<ClientForm
          ref="clientForm"
          v-model="client"
          submitText="Salvar"
          @submit="saveClient"
        />
      </v-card-text>

    </v-card>
	</div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import ClientForm from "./ClientForm";

export default {
  name: "EditClient",
  components: { PageBreadcrumbs, ClientForm },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  },
  data() {
    return {
      client: {categories: {},},
    };
  },
  methods: {
    loadClient() {
      this.$store.commit("setLoading", true);
      this.$axios.get(`/clients/${this.$route.params.id}`)
        .then(response => {
          this.client = response.data;
        })
        .catch(error => {
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao buscar dados do cliente"
          );
          this.$router.go(-1);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
    saveClient() {
      this.$store.commit("setLoading", true);
      this.$axios.put('/clients', this.client)
        .then(response => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Cliente alterado com sucesso"
          );
          this.$refs.clientForm.reset();
          this.$router.go(-1);
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao alterar cliente"
          );
        });
    }
  },
  mounted() {
    this.loadClient();
  }
};
</script>
