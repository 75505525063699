<template>
  <div>
    <PageBreadcrumbs :items="[['Minhas Tarefas','/tasks']]">
      <v-btn
        to="/tasks/completed"
        depressed
        color="secondary lighten-3 white--text"
        class="mr-3 font-weight-medium"
        style="font-size: 11px"
      >
        Ver Tarefas Concluídas
        <v-icon right small>mdi-archive</v-icon>
      </v-btn>
      <v-menu>
        <template #activator="{ on }">
          <v-btn
            color="success" depressed min-width="0"
            class="mr-3 px-2"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{name: 'Patterns'}">
            <v-list-item-subtitle class="grey--text text--darken-4">
              Padrões
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item :to="{name: 'NewTask'}">
            <v-list-item-subtitle class="grey--text text--darken-4">
              Nova Tarefa em Branco
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-menu>
    </PageBreadcrumbs>

    <TasksList />

  </div>
</template>

<script>
import { PageBreadcrumbs, TasksList } from "@/components/core";

export default {
  name: "Tasks",
  components: { PageBreadcrumbs, TasksList },
};
</script>
