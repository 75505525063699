<template>
  <v-form ref="generalDataForm">
    <span class="headline">Dados Gerais</span>
    <v-divider class="mt-1 mb-3" />
    <v-text-field
      v-model="task.name"
      filled label="Nome da tarefa *"
      :rules="[$rules.required]"
      :disabled="disabled"
      hide-details="auto"
    />

    <v-select
      label="Setor da Tarefa *" filled
      v-model="task.sector_id"
      :items="sectors" item-value="id" item-text="name"
      :rules="[$rules.required]" :loading="loadingSectors"
      hide-details="auto"
    />

    <v-textarea
      filled label="Descrição"
      v-model="task.description"
      auto-grow :disabled="disabled" hide-details="auto"
    />

    <v-menu
      v-if="!task.pattern"
      v-model="datePicker"
      :close-on-content-click="false"
      offset-y max-width="300px"
      :disabled="disabled"
      >
      <template v-slot:activator="{on}">
        <v-text-field
          v-model="task.deadline"
          :disabled="disabled" :rules="[$rules.date]"
          v-on="on" label="Data de Término"
          filled prepend-inner-icon="date_range" class="mr-1"
          v-mask="['##/##/####']" return-masked-value
          hide-details="auto"
        />
      </template>
      <v-date-picker
        @input="selectedDate"
        no-title full-width max
        :min="new Date().toISOString().substr(0, 10)"
        :show-current="false"
        next-icon="chevron_right"
        prev-icon="chevron_left"
      />
    </v-menu>

    <v-checkbox
      v-if="!task.pattern"
      v-model="task.highPriority" :disabled="disabled"
      label="Prioridade Alta"
      off-icon="check_box_outline_blank" on-icon="check" ripple
      hide-details="auto"
    />

    <v-checkbox
      v-if="!task.pattern"
      v-model="task.defineClientTaskResponsible" :disabled="disabled"
      label="Definir Reponsável por cada Cliente"
      off-icon="check_box_outline_blank" on-icon="check" ripple
      hide-details="auto"
    />

  </v-form>
</template>

<script>
export default {
  name: "TaskGeneralData",
  props: ["value", "disabled"],
  data: function(){
    return {
      task: this.value,
      datePicker: false,
      timePicker: false,
      sectors: [],
      loadingSectors: true,
    }
  },
  watch: {
    value(val) {
      this.task = val;
    },
    task(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    selectedDate(date) {
      this.datePicker = false;
      const [year, month, day] = date.split("-");
      this.task.deadline = `${day}/${month}/${year}`;
    },
    validate() {
      return this.$refs.generalDataForm.validate();
    },
  },
  mounted(){
    this.loadingSectors = true
    this.$axios.get('/user/register/sectors')
      .then((response) => {
        this.sectors = response.data
      })
      .catch(() => {
        this.$store.commit(
          "showNotificationMessage",
          "Erro ao carregar setores",
        );
        this.$router.go(-1);
      })
      .finally(() => {
        this.loadingSectors = false
      })
  },
};
</script>
