function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
function normalizeStr(str) {
  if (typeof str != "string") {
    return String(str);
  }
  return removeAccents(str.toLowerCase());
}
function containsQuery(query, data) {
  for (var key in data) {
    var val = data[key];
    if (val instanceof Array || val instanceof Object) {
      if (containsQuery(query, val)) {
        return true;
      }
    } else {
      var val = normalizeStr(data[key]);
      if (val.includes(query)) {
        return true;
      }
    }
  }
}
function filterData(query, data) {
  var filtered = [];
  var query = normalizeStr(query);
  if (data) {
    data.forEach(row => {
      if (containsQuery(query, row)) {
        filtered.push(row);
      }
    });
  }
  return filtered;
}
function timestamp2str(d) {
  return new Date(d * 1000).toLocaleDateString('pt-BR');
}
function timestamp2strDatetime(d) {
  try {
    let date = new Date(d * 1000);
    return date.toLocaleDateString('pt-BR')
           + ' ' +
           date.toLocaleTimeString('pt-BR');
  } catch (e) {}
}
let re = /\s((http|https):\/\/)?([a-zA-Z][a-zA-Z0-9]+\.)+([a-zA-Z][a-zA-Z0-9]+)/;
function parseText(txt){
  let maxLoops = 10;
  let match;
  while ((match = re.exec(txt)) != null){
    let matchTxt = match[0].slice(1);
    txt = txt.replace(matchTxt,
      `<a href="${ parseLink(matchTxt) }" target="_blank">${ matchTxt }</a>`
    );
    maxLoops--;
    if (maxLoops == 0){
      return txt;
    }
  }
  return txt.replace(/\n/g, '<br/>');
}
function parseLink(link){
  if (
    !link.toLowerCase().startsWith('http://')
  && !link.toLowerCase().startsWith('https://')
  ){
    return 'http://' + link;
  }
  return link;
}
export default {
  removeAccents,
  normalizeStr,
  filterData,
  timestamp2str,
  timestamp2strDatetime,
  parseText,
  parseLink,
};
