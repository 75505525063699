import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ripple from 'vuetify/lib/directives/ripple'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify, { directives: { ripple } })

const theme = {
//   primary: '#008390',
//   secondary: '#006D75',
//   tertiary: '#FF9800',
//   accent: '#e91e63',
//   info: '#00CAE3',
//   success: '#4CAF50',
//   warning: '#FB8C00',
//   error: '#FF5252',
}

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
