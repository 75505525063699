<template>
	<v-form ref="taskFiltersForm">
		<span class="headline">Seleção de Clientes</span>
		<v-divider class="mt-1 mb-1" />
		<v-layout wrap>
        <template v-for="(filter, i) in task.filters">
          <v-flex :key="i" xs12 md6>
            <v-scroll-x-transition @after-leave="spliceFilter()">
              <TaskFilter
                v-model="task.filters[i]" v-if="hiddenFilter != i"
                @delete="deleteFilter(i)" :allow-delete="task.filters.length > 1"
                :categories="categories"
                :location="location"
                @updateFilteredClients="updateFilteredClients(i, $event)"
              />
            </v-scroll-x-transition>
          </v-flex>
        </template>
      <v-flex mx-1 xs12>
        <v-layout wrap>
          <v-spacer/>
          <v-dialog v-model="filteredClientsDialog" max-width="500">
            <template #activator="{ on }">
              <v-btn color="info" class="elevation-3 mt-4" v-on="on" :loading="loadingClients">
                {{filteredClients.length}} clientes filtrados
                <v-icon class="ml-2">search</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="title">
                {{filteredClients.length}} Clientes Filtrados
              </v-card-title>
              <v-card-text>

                <v-simple-table fixed-header height="400">
                  <thead>
                    <tr>
                      <th class="text-left">Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="client in filteredClients" :key="client.id">
                      <td>{{ client.name }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>

              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text color="info" @click="filteredClientsDialog = false">
                  fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn @click="newFilter()" color="primary" class="elevation-3 ml-4 mt-4">
            Novo filtro
          </v-btn>
        </v-layout>
      </v-flex>
		</v-layout>
	</v-form>
</template>

<script>
import TaskFilter from './TaskFilter';
export default {
  name: "TaskFilters",
  components: { TaskFilter },
  props: ['value', 'disabled', 'isCreatingTask'],
  data(){
    return {
      task: this.value,
      filteredClients: [],
      filteredClientsDialog: false,
      location: [],
      categories: [],
      hiddenFilter: -1,
      loadingClients: false,
    };
  },
  watch: {
    value(val) {
      this.task = val;
    },
    'task.filters': {
      deep: true,
      handler(val) {
        this.loadFilteredClients()
      }
    }
  },
  methods: {
    loadFilteredClients(){
      this.loadingClients = true
      this.$axios.post('/filter', this.task.filters)
        .then((response) => {
          this.filteredClients = response.data
          this.$emit('update:filteredClients', response.data)
        })
        .finally(() => {
          this.loadingClients = false
        })
    },
    newFilter() {
      this.hiddenFilter = this.task.filters.length;
      this.task.filters.push({
        filterLocation: false,
        filterCategories: [{}],
        country: -1,
        state: -1,
        city: -1,
        client: -1,
      });
      this.$nextTick(() => {
        this.hiddenFilter = -1;
      })
    },
    deleteFilter(index) {
      this.hiddenFilter = index;
    },
    spliceFilter(){
      let index = this.hiddenFilter
      this.hiddenFilter = -1;
      this.$nextTick(() => {
        this.task.filters.splice(index, 1);
        this.filteredClients.splice(index, 1);
      });
    },
    validate(){
      return this.$refs.taskFiltersForm.validate();
    },
    fetchData() {
      this.$store.commit("setLoading", true);
      this.$axios.get('/clients/data')
        .then(response => {
          this.categories = response.data.categories;
          let location = response.data.location;
          location.unshift({ id: -1, name: 'Todos os países', states: [] });
          location.forEach(country => {
            country.states.unshift({ id: -1, name: 'Todos os estados', cities: [] });
            country.states.forEach(state => {
              state.cities.unshift({ id: -1, name: 'Todas as cidades' });
            });
          });
          this.location = location;
          if (this.isCreatingTask) {
            this.newFilter();
          }
        })
        .catch(error => {
          console.log(error);
          this.$emit("error");
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        })
    },
  },
  created() {
    this.fetchData();
  }
};
</script>
