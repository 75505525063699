<template>
  <v-form ref="taskClientsResponsiblesSelectorForm">
		<span class="headline">Seleção de Responsáveis por Clientes</span>
		<v-divider class="mt-1 mb-1" />

    <v-card class="mx-1 mt-4 px-1">
      <v-row class="font-weight-medium py-2">
        <v-col cols="6" class="pl-5">
          Cliente
        </v-col>
        <v-col cols="6" class="pr-5">
          Responsável
        </v-col>
      </v-row>

      <v-divider />

      <v-virtual-scroll :items="clientsResponsibles" bench="2" height="300" itemHeight="46">
        <template #default="{ item }">
          <v-row no-gutters align="center">
            <v-col cols="6" class="pl-5 pr-1">
              {{ item.client_name }}
            </v-col>
            <v-col cols="6" class="pr-5">
              <ResponsibleSelect
                v-model="item.responsible"
                :rules="[$rules.required]"
                :responsibles="responsibles"
                solo dense
                class="my-1 ml-1"
              />
            </v-col>
          </v-row>
          <v-divider />
        </template>
      </v-virtual-scroll>

    </v-card>
  </v-form>
</template>

<script>
import ResponsibleSelect from './ResponsibleSelect'
export default {
  name: "TaskClientsResponsiblesSelector",
  components: { ResponsibleSelect },
  props: ['value', 'filteredClients'],
  data(){
    return {
      task: this.value,
      clientsResponsibles: [],
      responsibles: [],
    }
  },
  watch: {
    value(val) {
      this.task = val;
    },
    task: {
      deep: true,
      handler(val){
        this.$emit('input', val)
      },
    },
    filteredClients(){
      this.updateClientsResponsibles()
    },
    clientsResponsibles(){
      this.task.clientsResponsibles = this.clientsResponsibles
    },
  },
  methods: {
    validate() {
      return this.$refs.taskClientsResponsiblesSelectorForm.validate();
    },
    updateClientsResponsibles(){
      this.clientsResponsibles = this.filteredClients.map((client) => ({
        client_id: client.id,
        client_name: client.name,
        responsible: 'sector/' + this.task.sector_id,
      }))
    },
    fetchUsersData() {
      this.$store.commit("setLoading", true);
      this.$axios.get('/tasks/users')
        .then(response => {
          let sectors = response.data;
          let responsibles = [{ name: 'Todos os Usuários', id: '*' }];
          sectors.forEach((sector) => {
            responsibles.push({
              name: sector.name,
              id: `sector/${sector.id}`,
              type: 'sector',
            });
            sector.users.forEach((user) => {
              responsibles.push({
                name: user.name,
                id: `user/${user.id}`,
                type: 'user',
              });
            });
          });
          this.responsibles = responsibles;
        })
        .catch(error => {
          this.$emit("error");
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        })
    },
  },
  mounted(){
    this.fetchUsersData()
    this.updateClientsResponsibles()
  },
}
</script>