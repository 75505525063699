<template>
  <v-layout align-center justify-center fill-height style="background: #edecec">

    <div
      style="
        background: #00bcd4;
        position: fixed;
        display: block;
        height: 50%;
        width: 100%;
        top: 0;
      "
    >
    </div>

    <v-flex mx-1 my-1 xs10 sm8 md6 lg5 xl3
    >
      <v-card class="elevation-15 py-2" :class="{'px-4': $vuetify.breakpoint.smAndUp}">
        <v-card-title>
          <span class="headline">
            Tarefas —
            {{
              registerPage
              ? "Cadastro"
              : "Área de usuário"
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-expand-transition>
            <v-form
              v-if="!registerPage"
              ref="loginForm" class="loginForm"
              @submit.prevent="login()"
            >
              <v-text-field
                v-model="loginUser.username"
                filled label="Nome de Usuário"
                :rules="[$rules.required,$rules.username]"
                :loading="loading"
              />
              <v-text-field filled label="Senha" v-model="loginUser.password"
                :rules="[$rules.required,$rules.passwordMin]"
                :type="showingPasswordLogin ? 'text':'password'"
                :loading="loading"
                :append-icon="showingPasswordLogin ? 'visibility_off' : 'visibility'"
                @click:append="showingPasswordLogin = !showingPasswordLogin"
              />
              <div class="d-flex align-center">
                <v-btn
                  color="primary" text class="px-2"
                  @click="registerPage = true"
                  :disabled="loading"
                >
                  Cadastrar-se
                </v-btn>
                <v-spacer />
                <v-tooltip top :open-delay="200">
                  <span>
                    Esqueceu sua senha?
                  </span>
                  <template #activator="{ on: onTooltip }">
                    <v-dialog max-width="350px" v-model="forgotPasswordDialog">
                      <v-card>
                        <v-card-title class="text-subtitle-1 pt-6">
                          Esqueceu sua senha?
                        </v-card-title>
                        <v-card-text class="pb-2 text-justify grey--text text--darken-4">
                          Contate o administrador do sistema, ele pode definir uma nova senha para você.
                        </v-card-text>
                        <v-card-actions class="pt-2 px-6 pb-6">
                          <v-spacer />
                          <v-btn
                            color="grey lighten-2"
                            depressed
                            @click="forgotPasswordDialog = false"
                          >
                            ok
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                      <template #activator="{ on: onDialog }">
                        <v-btn
                          icon large class="mr-2"
                          v-on="{ ...onTooltip, ...onDialog }"
                        >
                          <v-icon>help_outline</v-icon>
                        </v-btn>
                      </template>
                    </v-dialog>
                  </template>
                </v-tooltip>
                <v-btn color="success" large type="submit" :disabled="loading">
                  Login
                </v-btn>
              </div>
            </v-form>
          </v-expand-transition>
          <v-expand-transition>
            <v-form
              v-if="registerPage"
              ref="registerForm"
              class="loginForm"
              @submit.prevent="register"
            >
              <v-text-field filled label="Nome de Usuário *" :rules="[$rules.required,$rules.username]" v-model="registerUser.username" :disabled="loading" />
              <v-text-field filled label="Nome Completo *" :rules="[$rules.required]" v-model="registerUser.name" :disabled="loading" />
              <v-text-field filled label="E-Mail *" :rules="[$rules.required,$rules.email]" v-model="registerUser.email" :disabled="loading" />

              <v-select
                multiple filled
                v-model="registerUser.sectors" :items="sectors"
                :disabled="loading" :rules="[$rules.requiredList]"
                label="Setores *"
                item-text="name" item-value="id"
              />
              
              <v-text-field filled label="Senha *"
                :rules="[$rules.required,$rules.passwordMin]" v-model="registerUser.password"
                :type="showingPasswordRegister ? 'text':'password'"
                :disabled="loading"
                :append-icon="showingPasswordRegister ? 'visibility_off' : 'visibility'"
                @click:append="showingPasswordRegister = !showingPasswordRegister"
              />
              <v-text-field filled label="Confirmação da Senha *" :disabled="loading"
                :rules="[$rules.required,$rules.equalPassword(() => registerUser.password)]"
                :type="showingPasswordConfirm ? 'text':'password'"
                :append-icon="showingPasswordConfirm ? 'visibility_off' : 'visibility'"
                @click:append="showingPasswordConfirm = !showingPasswordConfirm"
              />
              <v-layout align-center justify-space-around class="pb-3" style="overflow: visible">
                <v-spacer />
                <v-btn color="secundary" class="ml-0 mr-2 text-xs-right" text
                  @click="registerPage = false"
                >
                  Cancelar
                </v-btn>
                <v-btn color="success" class="mr-0" type="submit" :loading="loading">
                  Cadastrar-se
                </v-btn>
              </v-layout>
            </v-form>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-snackbar v-model="notificationBar.active" :timeout="5000">
      {{notificationBar.message}}
      <template #action>
        <v-btn color="red" text @click="notificationBar.active = false">
          ok
        </v-btn>
      </template>
    </v-snackbar>

  </v-layout>
</template>

<script>
import { DynamicSelect } from "@/components/pages/forms/inputs";

export default {
  name: "Login",
  components: { DynamicSelect },
  data() {
    return {
      loginUser: { username: "", password: "" },
      registerUser: {
        username: "",
        password: "",
        name: "",
        email: "",
        sectors: [],
      },
      sectors: [],
      forgotPasswordDialog: false,
      showingPasswordLogin: false,
      showingPasswordRegister: false,
      showingPasswordConfirm: false,
      registerPage: false,
      loading: false,
      notificationBar: { active: false, message: "" }
    };
  },
  methods: {
    request(endpoint, data) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.notificationBar.active = false;
        this.$axios.post(endpoint, data)
          .then(response => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(() => {
            this.notificationBar.active = true;
            this.loading = false;
          })
      });
    },
    login() {
      if (this.$refs.loginForm.validate()) {
        this.request('/user/login', this.loginUser)
          .then(() => {
            this.$refs.loginForm.reset();
            this.notificationBar.message = "Login efetuado com sucesso";
          })
          .catch((error) => {
            switch (error.status) {
              case 401:
                this.notificationBar.message =
                  "Seu usuário está inativo/bloqueado, solicite ao administrador para ativar seu usuário";
                break;
              case 403:
                this.notificationBar.message = "Usuário ou senha incorreto(s)";
                break;
              default:
                this.notificationBar.message =
                  "Um erro inesperado aconteceu, tente novamente";
            }
          });
      }
    },
    register() {
      if (!this.$refs.registerForm.validate()){
        this.notificationBar.message =
                  "Preencha todos os campos";
        this.notificationBar.active = true;
      } else {
        this.request('/user/register', this.registerUser)
          .then(() => {
            this.notificationBar.message =
              "Cadastro efetuado com sucesso, solicite ao administrador para ativar seu usuário";
            this.$refs.registerForm.reset();
            this.registerPage = false;
          })
          .catch((error) => {
            switch (error.status) {
              case 409:
                this.notificationBar.message =
                  "Esse nome de usuário já está em uso";
                break;
              default:
                this.notificationBar.message =
                  "Um erro inesperado aconteceu, tente novamente";
            }
          });
      }
    }
  },
  beforeMount() {
    this.loading = true;
    this.$axios.get('/user/register/sectors')
      .then((response) => {
        this.sectors = response.data;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.notificationBar.message =
          "Um erro inesperado aconteceu ao buscar dados";
        this.notificationBar.active = true;
      });
  }
};
</script>
