<template>
    <div>
      <PageBreadcrumbs :items="[['Usuários','/users']]" />

      <v-flex xs12 mt-2>
        <v-card>
          <v-toolbar flat class="py-2 mt-1">
            <v-text-field
              class="mt-3"
              color="success"
              clearable
              prepend-inner-icon="search"
              v-model="searchQuery"
              label="Que usuário deseja buscar?"
            />
          </v-toolbar>

          <v-simple-table>
            <thead>
              <tr>
                <th> Nome </th>
                <th> Status </th>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <th> E-Mail </th>
                  <th> Nome de usuário </th>
                </template>
                <th> Ações </th>                
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in usersFiltered" :key="user.id">
                <td> {{user.name}} </td>
                <td> {{getUserStatus(user)}} </td>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <td> {{user.email}} </td>
                  <td> {{user.username}} </td>
                </template>
                <td>
                  <!-- active -->
                  <template v-if="user.isactive">

                    <!-- blocked -->
                    <v-tooltip top v-if="user.isblocked">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="error" small icon @click="unblock(user)" :disabled="loading">
                          <v-icon>lock_open</v-icon>
                        </v-btn>
                      </template>
                      <span>desbloquear</span>
                    </v-tooltip>

                    <!-- not blocked -->
                    <template v-else>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" color="error" small icon @click="block(user)" :disabled="loading">
                            <v-icon>block</v-icon>
                          </v-btn>
                        </template>
                        <span>bloquear</span>
                      </v-tooltip>

                      <!-- admin -->
                      <v-tooltip top v-if="user.type != 'admin'">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" color="warning" small icon @click="setAdmin(user)" :disabled="loading">
                            <v-icon>cloud_queue</v-icon>
                          </v-btn>
                        </template>
                        <span>tornar administrador</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" color="warning" small icon @click="removeAdmin(user)" :disabled="loading">
                            <v-icon>cloud_off</v-icon>
                          </v-btn>
                        </template>
                        <span>remover administrador</span>
                      </v-tooltip>

                      <!-- password -->
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" color="info" small icon :disabled="loading" @click="openPasswordDialog(user)">
                            <v-icon>lock</v-icon>
                          </v-btn>
                        </template>
                        <span>definir senha</span>
                      </v-tooltip>
                    </template>
                  </template>

                  <!-- not active -->
                  <template v-else>
                    <v-btn outlined color="success" small @click="activate(user)" :disabled="loading">
                      Ativar
                    </v-btn>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="error" small icon :disabled="loading" @click="openDeleteDialog(user)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>deletar usuário</span>
                    </v-tooltip>
                  </template>

                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-flex>
      
      <v-dialog persistent v-model="passwordDialog.visible" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="title" style="font-weight: 350">
              Definindo a senha de <strong>{{passwordDialog.user.name}}</strong>
            </span>
          </v-card-title>
          <v-card-text>
            <v-form ref="passwordDialog">
              <v-text-field
                filled label="Nova Senha"
                :type="passwordDialog.passwordVisible ? 'text':'password'" 
                v-model="passwordDialog.password"
                :rules="[rules.required, rules.passwordMin]"
                validate-on-blur
              >
                <v-btn slot="append" icon small class="mt-0" :disabled="loading"
                    @click="passwordDialog.passwordVisible = !passwordDialog.passwordVisible">
                  <v-icon>
                    {{passwordDialog.passwordVisible ? 'visibility_off':'visibility'}}
                  </v-icon>
                </v-btn>
              </v-text-field>
              <v-layout>
                <v-spacer />
                <v-btn color="error" text @click="passwordDialog.visible = false" :disabled="loading">
                  cancelar
                </v-btn>
                <v-btn color="warning" text @click="setPassword" :disabled="loading">
                  alterar senha
                </v-btn>
                </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteUserDialog" max-width="400px">
        <v-card>
          <v-card-title v-if="userDeleteIndex != -1">
            Tem certeza que deseja deletar o usuário "<strong>{{users[userDeleteIndex]['name']}}"</strong>?
          </v-card-title>
          <v-card-actions>
            <v-layout>
              <v-spacer />
              <v-btn color="info" text @click="deleteUserDialog = false">não</v-btn>
              <v-btn color="error" text @click="deleteUser">sim</v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import rules from "@/rules";
import utils from "@/utils";
export default {
  name: "Users",
  components: { PageBreadcrumbs },
  data: function() {
    return {
      rules,
      searchQuery: "",
      deleteUserDialog: false,
      userDeleteIndex: -1,
      passwordDialog: {
        visible: false,
        password: "",
        passwordVisible: false,
        user: {}
      },
      users: []
    };
  },
  computed: {
    loading: function() {
      return this.$store.getters.isLoading;
    },
    usersFiltered(){
      return utils.filterData(this.searchQuery, this.users);
    }
  },
  methods: {
    fetchUsers() {
      this.$store.commit("setLoading", true);
      this.$axios.get('/users')
        .then(response => {
          this.users = response.data;
          this.$store.commit("setLoading", false);
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao buscar usuários"
          );
        });
    },
    getUserStatus(user) {
      var arr = [];
      if (user['isblocked']) arr.push('bloqueado');
      else {
        arr.push(user["isactive"] ? "ativo" : "inativo");
        if (user.type == 'admin') arr.push("administrador");
      }
      return arr.join(", ");
    },
    makeRequest(endpoint, user, key, val) {
      this.$store.commit("setLoading", true);
      this.$axios.put(`/users/${endpoint}`, {id: user.id})
        .then(response => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Usuário alterado com sucesso"
          );
          user[key] = val;
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao alterar usuário"
          );
        });
    },
    block      (user) { this.makeRequest("block",        user, "isblocked", true    ); },
    unblock    (user) { this.makeRequest("unblock",      user, "isblocked", false   ); },
    setAdmin   (user) { this.makeRequest("set-admin",    user, 'type',      'admin' ); },
    removeAdmin(user) { this.makeRequest("remove-admin", user, 'type',      'normal'); },
    activate   (user) { this.makeRequest("activate",     user, 'isactive',  true    ); },
    openDeleteDialog(user) {
      this.deleteUserDialog = true;
      this.userDeleteIndex = this.users.indexOf(user);
    },
    openPasswordDialog(user) {
      this.passwordDialog.user = user;
      this.passwordDialog.visible = true;
      this.passwordDialog.passwordVisible = false;
      this.$nextTick(() => {
        this.$refs.passwordDialog.reset();
      });
    },
    setPassword() {
      if (this.$refs.passwordDialog.validate()) {
        this.$store.commit("setLoading", true);
        this.$axios.put('/users/set-password', {
          id: this.passwordDialog.user["id"],
          password: this.passwordDialog.password,
        })
          .then(response => {
            this.passwordDialog.visible = false;
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Senha alterada com sucesso"
            );
          })
          .catch(error => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Erro ao alterar senha"
            );
          });
      }
    },
    deleteUser() {
      this.deleteUserDialog = false;
      this.$axios.delete('/users', {params: {id: this.users[this.userDeleteIndex]["id"]}})
        .then(response => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Usuário deletado com sucesso"
          );
          this.users.splice(this.userDeleteIndex, 1);
          this.userDeleteIndex = -1;
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao deletar usuário"
          );
        });
    }
  },
  created() {
    this.fetchUsers();
  }
};
</script>
