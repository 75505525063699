var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{ref:"actionButton",attrs:{"icon":"","small":"","disabled":_vm.loading},on:{"click":function($event){_vm.computeMenuPosition();
      _vm.optionsMenu = true;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1),_c('v-menu',{attrs:{"left":"","position-x":_vm.menuPosition.x,"position-y":_vm.menuPosition.y},model:{value:(_vm.optionsMenu),callback:function ($$v) {_vm.optionsMenu=$$v},expression:"optionsMenu"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.updateMenu = true}}},[_c('v-list-item-title',[_vm._v(" Marcar subtarefas do cliente em aberto ")])],1),_c('v-list-item',{attrs:{"to":{
          name: 'ClientTask',
          params: {
            task: _vm.task_id,
            client: _vm.clientTask.client.id
          }
        }}},[_c('v-list-item-title',[_vm._v(" Ir para tarefa do cliente ")])],1)],1)],1),_c('v-menu',{attrs:{"left":"","min-width":"200px","close-on-content-click":false,"position-x":_vm.menuPosition.x,"position-y":_vm.menuPosition.y},model:{value:(_vm.updateMenu),callback:function ($$v) {_vm.updateMenu=$$v},expression:"updateMenu"}},[_c('UpdateSelector',{ref:"updateSelector",attrs:{"updateOptions":_vm.updateOptions,"loading":_vm.loading,"clientTask_id":_vm.clientTask.id},on:{"close":function($event){_vm.updateMenu = false},"added-update":_vm.addUpdate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }