<template>
  <div>
    <PageBreadcrumbs :items="[['Clientes','/clients']]" />

    <v-flex xs12 mt-2>
      <v-card>
        <v-layout class="py-2 px-4" align-center>
          <v-text-field
            class="mt-3 mr-4"
            color="success"
            clearable
            prepend-inner-icon="search"
            v-model="searchQuery"
            label="Que cliente deseja buscar?"
            :disabled="loading"
            :loading="loading"
          />
          <v-btn class="mt-1" color="primary" center to="/clients/new" :disabled="loading"> Novo cliente </v-btn>
        </v-layout>
        
        <CustomTable
          ref="dataTable"
          :itemsLength="filteredClients.length" itemsText="clientes"
          @changedPageData="loadClients"
          :loading="loading"
        >
          <template v-slot:thead>
            <th> Cliente </th>
            <template v-if="$vuetify.breakpoint.smAndUp">
              <th> Cidade </th>
              <th> Telefone </th>
            </template>
            <th> Ações </th>
          </template>
          <template v-slot:tbody>
            <tr v-for="client in pageClients" :key="client.id">
              <td :style="client.isactive ? '':inactiveStyle">
                {{client.name}}
              </td>
              <template v-if="$vuetify.breakpoint.smAndUp">
                <td :style="client.isactive ? '':inactiveStyle">
                  {{client.city}}
                </td>
                <td :style="client.isactive ? '':inactiveStyle">
                  {{client.phone[0] || client.cellphone[0] || '-'}}
                </td>
              </template>
              <td :style="client.isactive ? '':inactiveStyle">
                <v-tooltip top :close-delay="0" :open-delay="500">
                  <span>editar</span>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="info" small icon :to="`/clients/edit/${client.id}`">
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
                <!-- <v-tooltip top :close-delay="0" :open-delay="500">
                  <span>página do cliente</span>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="primary" small icon>
                      <v-icon>assignment</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip> -->
                <template v-if="client.isactive">
                  <v-tooltip top :close-delay="0" :open-delay="500">
                    <span>desativar</span>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="amber darken-4" small icon
                            @click="deactivate(client)">
                      <v-icon>not_interested</v-icon>
                    </v-btn>
                    </template>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-tooltip top :close-delay="0" :open-delay="500">
                    <span>ativar</span>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="green lighten-2" small icon
                            @click="activate(client)">
                        <v-icon>check</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </CustomTable>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { PageBreadcrumbs, CustomTable } from "@/components/core";

export default {
  name: "Clients",
  components: { PageBreadcrumbs, CustomTable },
  data() {
    return {
      inactiveStyle: "background: #BDBDBD",
      searchQuery: this.$route.params.searchQuery,
      clients: [],
      pageClients: [],
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    },
    filteredClients(){
      this.$nextTick(() => {
        this.$refs.dataTable.update();
      });
      if (!this.searchQuery) return this.clients;
      return this.$utils.filterData(this.searchQuery, this.clients);
    },
  },
  watch: {
    searchQuery(txt){
      let params = {
        pageSize: this.$route.params.pageSize || 10,
        page: this.$route.params.page || 1,
      };
      if (txt) params.searchQuery = txt;
      this.$router.replace({ name: "Clients", params });
    },
  },
  methods: {
    loadClients(begin, limit){
      this.pageClients = this.filteredClients.slice(begin, begin+limit);
    },
    fetchClients() {
      this.$store.commit("setLoading", true);
      this.$axios.get('/clients')
      .then(response => {
          this.clients = response.data;
          this.$store.commit("setLoading", false);
          this.$refs.dataTable.update();
      })
      .catch(error => {
        this.$store.commit("setLoading", false);
        this.$store.commit(
          "showNotificationMessage",
          "Erro ao buscar clientes"
        );
      });
    },
    deactivate(item) {
      var index = this.clients.indexOf(item);
      this.$store.commit("setLoading", true);
      this.$axios.put('/clients/deactivate', {id: item.id})
        .then(response => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Cliente desativado com sucesso"
          );
          this.$set(this.clients[index], "isactive", false);
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao desativar cliente"
          );
        });
    },
    activate(item) {
      var index = this.clients.indexOf(item);
      this.$axios.put('/clients/activate', {id: item.id})
        .then(response => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Cliente ativado com sucesso"
          );
          this.$set(this.clients[index], "isactive", true);
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao ativar cliente"
          );
        });
    }
  },
  created() {
    this.fetchClients();
  }
};
</script>
