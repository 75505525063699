<template>
  <div>
    <PageBreadcrumbs :items="[['Tarefas Padrões','/tasks/patterns']]" />

    <v-flex xs12 mt-2>
      <v-card>
        <div class="d-flex align-start py-2 px-2">
          <v-text-field
            class="mt-0 mr-2"
            color="info"
            clearable
            dense filled
            prepend-inner-icon="search"
            hide-details
            v-model="searchQuery"
            label="Que tarefa padrão deseja buscar?"
            :disabled="loading"
            :loading="loading"
          />
          <v-btn
            height="53px"
            class="py-0"
            color="primary" center
            to="/tasks/pattern/new" :disabled="loading"
          >
            novo padrão
          </v-btn>
        </div>
        
        <CustomTable
          ref="dataTable"
          :itemsLength="patterns.length" itemsText="tarefas padrões"
          @changedPageData="loadPatterns"
          :loading="loading"
        >
          <template v-slot:thead>
            <th class="text-left pl-4 py-2"> Tarefa Padrão </th>
            <th><!-- Ações --></th>
          </template>
            <template v-slot:tbody>
              <tr v-for="pattern in pagePatterns" :key="pattern.id">
                <td>
                  {{pattern.name}}
                </td>
                <td class="text-center">
                  <v-tooltip top :close-delay="0" :open-delay="500">
                    <span>Editar</span>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="info" small icon :to="`/tasks/pattern/edit/${pattern.id}`">
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                  
                  <v-btn
                    class="my-1"
                    color="primary" small outlined
                    :to="`/tasks/pattern/use/${pattern.id}`"
                  >
                    usar padrão
                  </v-btn>
                </td>
              </tr>
            </template>
        </CustomTable>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { PageBreadcrumbs, CustomTable } from "@/components/core";

export default {
  name: "Patterns",
  components: { PageBreadcrumbs, CustomTable },
  data() {
    return {
      patterns: [],
      pagePatterns: [],
      searchQuery: "",
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    },
    filteredPatterns(){
      this.$nextTick(() => {
        this.$refs.dataTable.update();
      });
      if (!this.searchQuery) return this.patterns;
      return this.$utils.filterData(this.searchQuery, this.patterns);
    },
  },
  watch: {
    searchQuery(txt){
      let params = {
        pageSize: this.$route.params.pageSize || 10,
        page: this.$route.params.page || 1,
      };
      if (txt) params.searchQuery = txt;
      this.$router.replace({ name: "Patterns", params });
    },
  },
  methods: {
    loadPatterns(begin, limit){
      this.pagePatterns = this.filteredPatterns.slice(begin, begin+limit);
    },
    filter(items, search, filter) {
      return this.$utils.filterData(search, items);
    },
    fetchPatterns() {
      this.$store.commit("setLoading", true);
      this.$axios.get('/tasks/patterns')
        .then(response => {
          this.patterns = response.data;
          this.$store.commit("setLoading", false);
          this.$refs.dataTable.update();
        })
        .catch(error => {
          this.$store.commit("setLoading", false);
          this.$store.commit(
            "showNotificationMessage",
            "Erro ao buscar tarefas padrões"
          );
        });
    }
  },
  created() {
    this.fetchPatterns();
  }
};
</script>
