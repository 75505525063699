<template>
  <v-menu
    v-model="updatePanel"
    :close-on-content-click="false"
    min-width="200px"
  >
    <template v-slot:activator="{ on: menu }">
      <v-tooltip top :open-delay="150">
        <template v-slot:activator="{ on: tooltip }">
          <div v-on="tooltip">
            <v-btn
              :color="lastUpdate.option.color || 'grey'"
              v-on="menu" small icon
              :disabled="loading"
              @click="optionSelected = {id: -1}"
            >
              <v-icon>
                {{ lastUpdate.option.icon || "check_box_outline_blank" }}
              </v-icon>
            </v-btn>
          </div>
        </template>

        <span>
          {{ lastUpdate.option.name || "em aberto" }}
          <template v-if="!subtask.userIsResponsible">
            - Você <strong>não</strong> é responsável por essa subtarefa
          </template>
        </span>

      </v-tooltip>
    </template>

    <UpdateSelector
      ref="updateSelector"
      @close="updatePanel = false"
      :updateOptions="updateOptions"
      :withUpdates="withUpdates"
      :loading="loading"
      :subtask="subtask"
      @update:subtask="$emit('update:subtask', $event)"
    />

  </v-menu>
</template>

<script>
import UpdateSelector from './UpdateSelector.vue';

export default {
  name: "SubtaskCheckbox",

  props: {
    updateOptions: {type: Array, required: true},
    subtask: {type: Object, required: true},
    loading: {type: Boolean, default: false},
    withUpdates: {type: Boolean, default: false},
  },

  components: {
    UpdateSelector,
  },

  data: () => ({
    updatePanel: false,
  }),

  computed: {
    lastUpdate(){
      for (let i = 0; i < this.subtask.updates.length; ++i){
        let update = this.subtask.updates[i];
        if (update.option.name){
          return update;
        }
      }
      return {option: {}};
    },
  },

  watch: {
    updatePanel(current, old){
      if (!current && old){
        this.$refs.updateSelector.cancel();
      }
    },
  },
};
</script>
