<template>
  <div>
    <PageBreadcrumbs :items="[
      ['Minhas Tarefas','/tasks'],
      ['Padrões','/tasks/patterns'],
      ['Nova Tarefa Padrão','/tasks/pattern/new'],
    ]" />

    <TaskForm
      ref="taskForm"
      v-model="task"
      @finalize="createTask"
      :loading="loading"
      :createInitialFilter="false"
    />

  </div>
</template>

<script>
import { PageBreadcrumbs } from "@/components/core";
import TaskForm from "./TaskForm";

export default {
  name: "NewPatternTask",
  components: {
    PageBreadcrumbs,
    TaskForm
  },
  data() {
    return {
      task: {
        pattern: true,
        name: "",
        description: "",
        subtasks: [],
        filters: [],
        updateOptions: [],
      }
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  },
  methods: {
    createTask() {
      if (this.$refs.taskForm.validate()) {
        this.$store.commit("setLoading", true);
        var task = Object.assign({}, this.task);
        delete task.pattern;
        this.$axios.post('/tasks/patterns', task)
          .then(response => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Tarefa padrão cadastrada com sucesso"
            );
            this.$router.go(-1);
          })
          .catch(error => {
            this.$store.commit("setLoading", false);
            this.$store.commit(
              "showNotificationMessage",
              "Erro ao cadastrar tarefa padrão"
            );
          });
      }
    }
  }
};
</script>
