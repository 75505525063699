<template>
  <v-app id="app">
    <template v-if="logged">
      <Menu :mini.sync="mini" />
      <v-layout justify-start :class="{ mini, mainContent: true }">
        <v-flex>
          <v-container>
            <v-layout>
                <v-flex xs12>
                  <v-fade-transition leave-absolute>
                    <router-view />
                  </v-fade-transition>
                </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>

    <v-snackbar v-model="snackbar" :timeout="2000">
      {{snackMessage}}
      <template #action>
        <v-btn color="error" text @click="snackbar = false">
          ok
        </v-btn>
      </template>
    </v-snackbar>

    </template>
    <template v-else>
      <Login />
    </template>
  </v-app>
</template>

<script>
import Vue from "vue";
import { Menu } from "@/components/core";
import { Login } from "@/components/pages";

export default {
  name: "App",
  components: { Menu, Login },
  data: function() {
    return {
      mini: null,
      snackbar: false,
      snackMessage: ""
    };
  },
  computed: {
    logged() {
      return this.$store.getters.isLogged;
    },
    storeNotificationMessage() {
      return this.$store.getters.getNotificationMessage;
    },
    isDarkMode(){
      return this.$store.getters.isDarkMode;
    }
  },
  watch: {
    storeNotificationMessage(message) {
      if (message) {
        this.snackbar = false;
        this.snackMessage = message;
        this.snackbar = true;
        this.$store.commit("showNotificationMessage", null);
      }
    },
    isDarkMode(darkMode){
      this.$vuetify.theme.dark = darkMode;
    }
  },
  methods: {
    setLogged(logged) {
      this.$store.commit("setLogged", logged);
    },
    async logout(){
      await this.$axios.delete('/user/login');
      this.$store.commit('logout');
    },
  },
  created(){
    this.$vuetify.theme.dark = this.isDarkMode;
    Vue.prototype.$logout = this.logout;
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}

.fade-enter-active {
  transition-delay: 200ms;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.mainContent {
  /* margin-left: 300px; */
  margin-left: 260px;
  transition: 200ms;
}
.mainContent.mini {
  margin-left: 80px;
  transition: 200ms;
}
</style>
